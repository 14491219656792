import React, {Component} from "react";
import {Link} from "react-router-dom";

const url = process.env.PUBLIC_URL;

class ServiceArea extends Component {
  render() {
    return (
      <div className="service-area two pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="single-service">
                <h3>
                  <Link to={`${url}/about`}>MISSION</Link>
                </h3>
                <p>
                  To pay FGN employees on-time and accurately within statutory
                  and contractual obligations
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="single-service">
                <h3>
                  <Link to={`${url}/about`}>VISION</Link>
                </h3>
                <p>
                  To be receptive and responsive to FGN employees' payroll
                  needs.
                  <br/>
                  <br/>
                </p>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-xs-12">
              <div className="single-service">
                <h3>
                  <Link to={`${url}/about`}>CORE VALUES</Link>
                </h3>
                <p>
                  Confidentiality, Individuality/empathy, Teamwork, Honesty,
                  Accuracy/Timeliness, Trust/Accountability
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ServiceArea;
