import React, { Component } from "react";
import LandingPage from "../pages/LandingPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import ContactUsPage from "../pages/ContactUsPage";
import ComplaintFormPage from "../pages/ComplaintFormPage";
import LiveChatPage from "../pages/LiveChatPage";
import AboutUsPage from "../pages/AboutUsPage";
import ComingSoonPage from "../pages/ComingSoonPage";
import FaqPage from "../pages/FaqPage";
import HistoryPage from "../pages/HistoryPage";
import NewsAndEventsPage from "../pages/NewsAndEventsPage";
import EventDetailsPage from "../pages/EventDetailsPage";
import ErrorPage from "../pages/ErrorPage";

const url = process.env.PUBLIC_URL;

class Layout extends Component {
  render() {
    return (
      <Router>
        <Header />
        <Switch>
          <Route exact path={`${url}/`} component={LandingPage} />
          <Route path={`${url}/contact`} component={ContactUsPage} />
          <Route path={`${url}/complaint`} component={ComplaintFormPage} />
          <Route path={`${url}/chat`} component={LiveChatPage} />
          <Route path={`${url}/about`} component={AboutUsPage} />
          <Route path={`${url}/coming-soon`} component={ComingSoonPage} />
          <Route path={`${url}/support`} component={FaqPage} />
          <Route path={`${url}/history`} component={HistoryPage} />
          <Route path={`${url}/events`} component={NewsAndEventsPage} />
          <Route
            path={`${url}/event-details/:pageId`}
            component={EventDetailsPage}
          />
          <Route component={ErrorPage} />
        </Switch>
        <Footer />
      </Router>
    );
  }
}

export default Layout;
