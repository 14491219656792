import {
  ERROR_CREATING_CASE,
  CREATING_CASE,
  CREATED_CASE,
  CLEAR_ALERTS,
  CREATED_CONTACT_MESSAGE,
  CREATING_CONTACT_MESSAGE,
  ERROR_CREATING_CONTACT_MESSAGE,
  CREATING_SUBSCRIPTION,
  CREATED_SUBSCRIPTION,
  ERROR_CREATING_SUBSCRIPTION,
  ERROR_FETCHING_ALL_SERVICES,
  FETCHED_ALL_SERVICES,
  FETCHING_ALL_SERVICES,
  ERROR_FETCHING_ALL_POSTS,
  FETCHED_ALL_POSTS,
  FETCHING_ALL_POSTS,
  ERROR_GETTING_CSRF_TOKEN,
  GET_CSRF_TOKEN,
  ERROR_FETCHING_ALL_ABOUT_US,
  FETCHED_ALL_ABOUT_US,
  FETCHING_ALL_ABOUT_US,
  CLOSE_CHAT_MODAL,
  SHOW_CHAT_MODAL,
} from "../constants/frontend.constant";

const initialState = {
  isCreatingCase: false,
  isCreatedCase: false,
  createCaseHasError: false,
  createCaseError: "",

  isFetchingPosts: false,
  isFetchedPosts: false,
  fetchingPostsError: false,
  postsError: "",
  posts: [],

  isFetchingServices: false,
  isFetchedServices: false,
  fetchingServicesError: false,
  servicesError: "",
  services: [],

  isCreatingContactMessage: false,
  isCreatedContactMessage: false,
  hasErrorCreatingContactMessage: false,
  createContactMessageError: "",
  createdContactMessage: "",

  isCreatingSubscription: false,
  isCreatedSubscription: false,
  hasErrorCreatingSubscription: false,
  createSubscriptionError: "",
  createdSubscription: "",

  isFetchingAboutUs: false,
  isFetchedAboutUs: false,
  hasErrorFetchingAboutUs: false,
  fetchAboutUsError: "",
  fetchedAboutUs: "",

  showChatModal: false,

  aboutUs: [],

  case: [],
  cases: [],
  hasError: false,
  error: "",
  _csrf: "",
};

const frontendReducer = (state = initialState, { type, payload }) => {
  if (type === CREATING_CASE) {
    return {
      ...state,
      isCreatingCase: true,
    };
  }
  if (type === CREATED_CASE) {
    return {
      ...state,
      isCreatingCase: false,
      isCreatedCase: true,
      createCaseHasError: false,
      case: payload,
    };
  }
  if (type === ERROR_CREATING_CASE) {
    return {
      ...state,
      isCreatingCase: false,
      isCreatedCase: false,
      createCaseHasError: true,
      createCaseError: payload,
    };
  }

  if (type === CREATING_CONTACT_MESSAGE) {
    return {
      ...state,
      isCreatingContactMessage: true,
    };
  }
  if (type === CREATED_CONTACT_MESSAGE) {
    console.log(payload);
    return {
      ...state,
      isCreatingContactMessage: false,
      isCreatedContactMessage: true,
      hasErrorCreatingContactMessage: false,
      createdContactMessage: payload,
    };
  }
  if (type === ERROR_CREATING_CONTACT_MESSAGE) {
    return {
      ...state,
      isCreatingContactMessage: false,
      hasErrorCreatingContactMessage: true,
      createContactMessageError: payload,
    };
  }

  if (type === CREATING_SUBSCRIPTION) {
    return {
      ...state,
      isCreatingSubscription: true,
    };
  }
  if (type === CREATED_SUBSCRIPTION) {
    console.log(payload);
    return {
      ...state,
      isCreatingSubscription: false,
      isCreatedSubscription: true,
      createdSubscription: payload,
    };
  }
  if (type === ERROR_CREATING_SUBSCRIPTION) {
    return {
      ...state,
      isCreatingSubscription: false,
      hasErrorCreatingSubscription: true,
      createSubscriptionError: payload,
    };
  }

  if (type === FETCHING_ALL_POSTS) {
    return {
      ...state,
      isFetchingPosts: true,
    };
  }
  if (type === FETCHED_ALL_POSTS) {
    return {
      ...state,
      isFetchingPosts: false,
      isFetchedPosts: true,
      fetchingPostsError: false,
      posts: payload,
    };
  }
  if (type === ERROR_FETCHING_ALL_POSTS) {
    return {
      ...state,
      isFetchingPosts: false,
      isFetchedPosts: false,
      fetchingPostsError: true,
      postsError: payload,
    };
  }

  if (type === FETCHING_ALL_SERVICES) {
    return {
      ...state,
      isFetchingServices: true,
    };
  }
  if (type === FETCHED_ALL_SERVICES) {
    return {
      ...state,
      isFetchingServices: false,
      isFetchedServices: true,
      fetchingServicesError: false,
      services: payload,
    };
  }
  if (type === ERROR_FETCHING_ALL_SERVICES) {
    return {
      ...state,
      isFetchingServices: false,
      isFetchedServices: false,
      fetchingServicesError: true,
      servicesError: payload,
    };
  }

  if (type === CLEAR_ALERTS) {
    return {
      ...state,
      createCaseHasError: state.createCaseHasError ? false : null,
      isCreatedCase: state.isCreatedCase ? false : null,
      hasErrorCreatingContactMessage: state.hasErrorCreatingContactMessage
        ? false
        : null,
      isCreatedContactMessage: state.isCreatedContactMessage ? false : null,
      isCreatedSubscription: state.isCreatedSubscription ? false : null,
    };
  }

  if (type === GET_CSRF_TOKEN) {
    return {
      ...state,
      _csrf: payload,
    };
  }
  if (type === ERROR_GETTING_CSRF_TOKEN) {
    return {
      ...state,
      error: payload,
    };
  }

  if (type === FETCHING_ALL_ABOUT_US) {
    return {
      ...state,
      isFetchingAboutUs: true,
    };
  }
  if (type === FETCHED_ALL_ABOUT_US) {
    return {
      ...state,
      isFetchingAboutUs: false,
      isFetchedAboutUs: true,
      hasErrorFetchingAboutUs: false,
      aboutUs: payload,
    };
  }
  if (type === ERROR_FETCHING_ALL_ABOUT_US) {
    return {
      ...state,
      isFetchingAboutUs: false,
      isFetchedAboutUs: true,
      hasErrorFetchingAboutUs: false,
      fetchAboutUsError: payload,
    };
  }

  if (type === SHOW_CHAT_MODAL) {
    return {
      ...state,
      showChatModal: payload,
    };
  }

  if (type === CLOSE_CHAT_MODAL) {
    return {
      ...state,
      showChatModal: payload,
    };
  }

  return state;
};
export default frontendReducer;
