import React from "react";
import { Modal } from "react-bootstrap";
import ChatForm from "../forms/ChatForm";

const ChatFormModal = ({
  show,
  handleClose,
  brand,
  chatSchema,
  handleSubmit,
  initialValues,
  lang,
}) => {
  return (
    <Modal
      show={show}
      onHide={() => handleClose(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Chat with our agents
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h6 className="text-center my-5">
          Please fill out the form below to start chatting with the next
          available agent.
        </h6>
        <ChatForm
          brand={brand}
          chatSchema={chatSchema}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          lang={lang}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ChatFormModal;
