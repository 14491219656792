import React from "react";
import LoaderImage from "../../assets/img/ajax-loader.gif";
import "./style.css";
export default function Loader({ showLoader }) {
  return (
    <div
      className="loader-modal"
      style={{ display: showLoader ? "block" : "none" }}
    >
      <div className="center-loader">
        <img alt="" src={LoaderImage} />
      </div>
    </div>
  );
}
