import React, { Component, createRef, Fragment } from "react";
import Layout from "./components/Layout";
import bootstrap from "./utils/bootstrap";
import { connect } from "react-redux";
import { fetchPosts } from "./actions/frontend.action";
import { openChatModal, closeChatModal } from "./actions/frontend.action";
import ChatFormModal from "./components/modals/ChatFormModal";
import * as Yup from "yup";

const chatSchema = Yup.object().shape({
  username: Yup.string()
    .max(20, "Name is too long")
    .required("Please provide username"),
  email: Yup.string()
    .max(25, "Email too long")
    .email("Invalid email address")
    .required("Please provide email"),
});

class App extends Component {
  state = {
    initialValues: {
      username: "",
      email: "",
    },
    url: "",
    milliseconds: "",
    displayChatModal: false,
  };

  brand = createRef();
  lang = createRef();

  componentDidMount() {
    bootstrap();
    this.props.fetchPosts();
  }

  handleSubmit = (values) => {
    const {
      REACT_APP_CHAT_TENANT,
      REACT_APP_CHAT_CLIENT_TYPE,
      REACT_APP_CHAT_URL,
    } = process.env;
    const { username, email } = values;
    const lang = this.lang.current.value;
    const brand = this.brand.current.value;
    const milliseconds = new Date().getTime();
    const flag = new Date().getTime();
    const attachedData = JSON.stringify({
      CHATTYPE: brand,
      REGNUM: "111222333",
      CUSTOMERNAME: "Tom Smith",
    });
    const width = "320px";
    const height = "520px";
    const url = `${REACT_APP_CHAT_URL}?realm=${REACT_APP_CHAT_TENANT}&lang=${lang}&clientType=${REACT_APP_CHAT_CLIENT_TYPE}&userName=${username}&flag=${flag}&userEmail=${email}&attachedData=${attachedData}`;
    document.open(
      url,
      `ippis-chat ${milliseconds}`,
      `width=${width},height=${height}`
    );
    this.props.closeChatModal();
  };

  render() {
    const { initialValues, milliseconds, url } = this.state;
    return (
      <Fragment>
        <Layout />
        <div className="btn-group-fab" role="group" aria-label="FAB Menu">
          <div>
            <button
              onClick={() => this.props.openChatModal(true)}
              type="button"
              className="btn btn-main btn-primary has-tooltip"
              data-placement="left"
              title="Menu"
            >
              <i className="fa fa-comments fa-2x" />
            </button>
          </div>
        </div>
        <ChatFormModal
          lang={this.lang}
          initialValues={initialValues}
          handleSubmit={this.handleSubmit}
          chatSchema={chatSchema}
          brand={this.brand}
          handleClose={this.props.closeChatModal}
          show={this.props.showChatModal}
          milliseconds={milliseconds}
          url={url}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  showChatModal: state.frontendReducer.showChatModal,
});

export default connect(mapStateToProps, {
  fetchPosts,
  openChatModal,
  closeChatModal,
})(App);
