import axios from "axios";
import config from "../helpers";

const { urlConfig } = config;
const { url } = urlConfig();

export const _getCsrfService = async () => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const request = await axios.get(`${url}/backend/authenticate`, {
    headers,
    withCredentials: true,
  });
  const response = await request;
  const { data } = response;
  return data;
};

export const fetchPostsService = async () => {
  const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
  };
  const request = await axios.get(`${url}/common/get-posts`, {
    headers,
    withCredentials: true,
  });
  const response = await request;
  return handleResponse(response);
};

export const fetchAllAboutUsService = async () => {
  const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
  };
  const request = await axios.get(`${url}/common/get-all-about-us`, {
    headers,
    withCredentials: true,
  });
  const response = await request;
  return handleResponse(response);
};

export const createCaseService = async (data, csrf) => {
  const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    "CSRF-Token": csrf,
  };
  const request = await axios.post(
    `${url}/frontend/create-case`,
    JSON.stringify(data),
    {
      headers,
    }
  );
  const response = await request;
  return handleResponse(response);
};

export const fetchServicesService = async () => {
  const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
  };
  const request = await axios.get(`${url}/frontend/all-cases`, {
    headers,
  });
  const response = await request;
  return handleResponse(response);
};

export const saveContactMessageService = async (data, csrf) => {
  const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    "CSRF-Token": csrf,
  };
  const request = await axios.post(
    `${url}/frontend/save-contact-message`,
    JSON.stringify(data),
    { headers, withCredentials: true }
  );
  const response = await request;
  return handleResponse(response);
};

export const saveSubscriptionService = async (data, csrf) => {
  const headers = {
    Accept: "application/json",
    "Content-type": "application/json",
    "CSRF-Token": csrf,
  };
  const request = await axios.post(
    `${url}/frontend/save-subscription`,
    JSON.stringify(data),
    { headers, withCredentials: true }
  );
  const response = await request;
  return handleResponse(response);
};

const handleResponse = (response) => {
  const { data } = response;
  const { success, message } = data;
  if (success) {
    return Promise.resolve(message);
  }
  return Promise.reject(message);
};
