import React, { Fragment } from "react";
import EventGallery from "./EventGallery";

const PostsArea = () => {
  return (
    <Fragment>
      <div className="subscribe-content section-title text-center pt-60">
        <h2>Recent Posts</h2>
      </div>
      <EventGallery landingPage={true} />
    </Fragment>
  );
};

export default PostsArea;
