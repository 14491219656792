import React, {Fragment} from "react";
import {Card, ListGroup, Accordion} from "react-bootstrap";
import Banner from "../components/banner/Banner";
import BannerImage from "../assets/img/banner/banner_new.jpg";

export default function FaqPage() {
  return (
    <Fragment>
      <Banner title="FAQ" BannerImage={BannerImage}/>
      <div className="blog-details-area pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="blog-details">
                <div className="blog-details-content">
                  <h1
                    style={{
                      fontSize: "30px",
                      color: "#303030",
                      fontWeight: "700",
                      fontFamily: "Open Sans, sans-serif",
                      textTransform: "uppercase",
                      marginBottom: "30px",
                      lineHeight: "26px"
                    }}
                  >
                    Frequently asked questions
                  </h1>
                  <Accordion>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="0">
                        Q1: How do I enroll into IPPIS?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <Card.Text>
                            a) You must be a bonafide staff of an MDA and
                            employed in line with prescribed public service
                            rules i.e. not less than 18 years{" "}
                            <strong>old and not more than 50 years.</strong>
                            <br/>
                            b) Registration is usually through the IPPIS project
                            portal www.ippis.gov.ng; however, after the initial
                            registration form to be completed by the employee
                            and uploaded into the IPPIS application before the
                            staff is registered&quot
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="2">
                        Q2: What are the required documents for enrolment?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="2">
                        <Card.Body>
                          <Card.Text>
                            a) Officer having concluded the self-service
                            enrollment on the project portal, a summary slip
                            would be generated for the officer to be endorsed by
                            appropriate officials of the MDA in duplicate
                            copies. The officer would keep one while the MDA
                            would keep one for the enrollment
                            <br/>
                            b) The officer must present himself physically for
                            biometric data capturing where the facial picture
                            would be taken (religious inclination of female
                            staff is recognized where only the face is taken
                            without requesting for the ears women in puddah
                            (nikaf) would be required to be taken in private by
                            a female official of the project consultants/federal
                            documents civil service commission)
                            <br/>
                            c) The following documents would be scanned into the
                            IPPIS application:
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;i) The self generated and
                            duly signed IPPIS Summary sheet;
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;ii) Staff letter/gazette of
                            first appointment – new employer need to submit
                            evidence of assumption (if any)
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;iv) Letter of last promotion
                            (if any) ;<br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;v) Birth
                            certificate/affidavit of declaration of age (not
                            newly done).
                            <br/>
                            d) Your ten finger impression would be taken as well
                            as your facial picture which will be stored on the
                            IPPIS application without which your salary would
                            not be processed
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="3">
                        Q3: Why do you require all these documents?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="3">
                        <Card.Body>
                          <Card.Text>
                            The document would become a permanent record in the
                            IPPIS application for future reference and the staff
                            would not be able to change these at will. This is
                            also to avoid falsification of record in the future.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="4">
                        Q4: Having enrolled, how can I change my bank or pension
                        administration?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="4">
                        <Card.Body>
                          <Card.Text>
                            a) You can only change your bank upon presentation
                            of letter of non indebtedness from your present bank
                            with an application informing of the details of your
                            new bank.
                            <br/>
                            b) Also, you are at liberty to change your pension
                            fund administrator in line with the provision of
                            pension reform act 2007
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="5">
                        Q5: When do IPPIS System pay salary and why do some
                        people don’t get their salary?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="5">
                        <Card.Body>
                          <Card.Text>
                            a) Salary through IPPIS is paid after the monthly
                            federal account allocation committee meeting; the
                            dates is not however specific but salary are paid
                            between 20th –25th of every month.
                            <br/>
                            b) some staff are not paid if there are
                            discrepancies in their bank details e.g.
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;i) If name in the bank is
                            different from what is on the IPPIS platform i.e.
                            some ladies after marriage rush to their banks to
                            change names without changing same on the IPPIS
                            application.
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;ii) Also some have names
                            like Chukwudi Ayobami Bello in the IPPIS application
                            but have Chukwudi Ayo Bello in the bank this is not
                            the same person and as such the salary will be
                            returned
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;iii) Some have accounts in
                            many bank and as such supplied e.g. ecobank as their
                            bank but forget and give the account number they
                            have with firstbank, this is mismatch
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;iv) Some fill in alphabet”O”
                            in the account number instead of numeric “0” (zero)
                            <br/>
                            &nbsp;&nbsp;&nbsp;&nbsp;v) If your finger print not
                            clear to the IPPIS application, it will reject
                            processing your salary. This is usually caused by
                            bad finger, heavy smoking, or drinking
                            (alcohol),stroke or amputation as a result of
                            accident or whitlow etc. exception is created to
                            allow these peculiarities be taken care of to enable
                            the system pay such officer.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="6">
                        Q6: I work for a ministry but I have been offer another
                        appointment with another government parastatals also
                        under IPPIS, what do I do?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="6">
                        <Card.Body>
                          <Card.Text>
                            You don’t need to register afresh, you will supply
                            your IPPIS Number to your new MDA and request that
                            your salary be migrated from ministry A to
                            Parastatal X with your new designation and salary
                            structureand level; this is done through the control
                            office in the office of the accountant general of
                            the federation.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="7">
                        Q7: I work for university of Lagosbut I also teach at
                        the Yaba College of technology, how will my two salaries
                        be paid under IPPIS?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="7">
                        <Card.Body>
                          <Card.Text>
                            a) You cannot work for two federal government
                            organizations i.e. you cannot have appointment
                            letter for full time employment in two organizations
                            in line with code of conduct act
                            <br/>
                            b) You must have a primary place of appointment
                            while any other employment would be part time.What
                            you will do is to register in only your primary
                            place of appointment while you inform your part-time
                            place of appointment of your IPPIS details. That
                            office will calculate your monthly allowance for the
                            part time assignment and upload into the IPPIS
                            platform using your IPPIS Number. This will reflect
                            in your pay slip, but chargeable to that office
                            personnel cost i.e. not chargeable to your primary
                            place of employment.
                            <br/>
                            c) The exception to this is for Research/Academic
                            staff on sabbatical leave. His principal place of
                            employment would pay his full salary while his place
                            of assignment would equally pay his full salary
                            within the same period. This is not counted as
                            double salary as there is approval from such
                            payment.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="8">
                        Q8: Having submitted my details, how will I stop my
                        salary upon retirement or resignation?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="8">
                        <Card.Body>
                          <Card.Text>
                            a) The system is programme to retire officer using
                            the date of appointment and date of birth to retire
                            officer who have attained 35 years of service or 60
                            years old for core public servant (whichever one
                            come first); OR 65 years old for research officers
                            in research institutions/tertiary institutions
                            (University, polytechnics and colleges of
                            Education).
                            <br/>
                            b) Similarly, all political appointees excluding CEO
                            who are to retire like core public servant have
                            programmed to continue until they leave office.
                            <br/>
                            c) If you resign suddenly or a staff dies, the Human
                            Resources Department (Role Player) in your
                            organization will EXIT the staff from payroll
                            immediately and this will reflect at the central
                            database in Abuja.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="9">
                        Q9: In this office and in line with Financial
                        Regulation, I used to obtain salary advance, how does
                        this operate under IPPIS system?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="9">
                        <Card.Body>
                          <Card.Text>
                            Under IPPIS, personnel costs are released monthly,
                            therefore, Government would not release in “advance”
                            personnel cost to enable you obtain salary advance.
                            This means that there is no salary advance under
                            IPPIS but you may approach your bank or cooperative
                            society etc as prescribed in the public service
                            rules for advance/loan.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="10">
                        Q10: I am a member of cooperative society, how would my
                        monthly contribution be deducted and remitted?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="10">
                        <Card.Body>
                          <Card.Text>
                            Your office IPPIS Role players would create the name
                            of the cooperative society on the IPPIS application
                            with its bank details. List of members and monthly
                            deduction is also programmed. As salaries are paid,
                            this is deducted from your salary and credited to
                            your cooperative society account. The society can
                            then generate the schedule of all contributors to
                            confirm this payment.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="11">
                        Q11: This office used to pay us 13th Month salary, but
                        we learnt IPPIS does not pay.Why?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="11">
                        <Card.Body>
                          <Card.Text>
                            There is no provision for 13th Month’s salary in the
                            financial regulation or any guidelines. Salaries are
                            paid for the months or days worked.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="12">
                        Q12: We learnt that IPPIS stopped the salaries of some
                        staff on the ground that they were not up to 15 years
                        old upon employment, is this justifiable?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="12">
                        <Card.Body>
                          <Card.Text>
                            In line with PSR, the minimum age of recruitment is
                            15 and maximum of 50; therefore, any age outside
                            could not be pay-rolled by the IPPIS application.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="13">
                        Q13: How does IPPIS system takes care of staff like
                        intern, NYSC, Clearance, Security Guard, contract staff;
                        etc?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="13">
                        <Card.Body>
                          <Card.Text>
                            The salaries or allowances of such officers are paid
                            through the Non Regular Allowance which is approved
                            by the budget office of the federation. They are not
                            pensionable staff and are not qualified to be
                            enrolled into IPPIS.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="14">
                        Q14: Where I do I get my payslip from?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="14">
                        <Card.Body>
                          <Card.Text>
                            The IPPIS Role player in the payroll section (salary
                            section) would issue your payslip to you on request;
                            but with time, there will be self-service opening
                            for you to generate your payslip on the internet or
                            e-mailer that would be sending your payslip to your
                            email just like you have statement of your account
                            on your e-mail.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="15">
                        Q15: Should there be any problem and I could not get my
                        salary, where would the money be kept and how would I
                        recover?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="15">
                        <Card.Body>
                          <Card.Text>
                            You must ensure that you provide correct details
                            about you and your bank so that your salary is not
                            returned. If it returned, the salary reverses back
                            to the central bank of Nigeria until you provide
                            necessary update to enable this office re-apply it.
                            This may however take tie depending on the time you
                            report. This reapplication is processed centrally
                            through your MDA Role players.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="16">
                        Q16: My colleague salary is more than your own, why?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="16">
                        <Card.Body>
                          <Card.Text>
                            a) Your colleague may be on a higher step than you
                            <br/>
                            b) If you belong to a cooperative society or on loan
                            to federal government staff housing loans board etc.
                            and you have deductions, your salary will be less
                            than your colleague
                            <br/>
                            c) If your salary structure is different from your
                            own e.g. CONPSS, CONPASS, CONHESS, etc.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="17">
                        Q17: How will I know my salary has been deposited into
                        my account?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="17">
                        <Card.Body>
                          <Card.Text>
                            if you have bank alert from your bank, you will be
                            notified or when your colleagues confirm receipt of
                            their salaries, you may also check your balance in
                            your bank via ATM, etc.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="18">
                        Q18: How do I have my annual increment effected on
                        IPPIS?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="18">
                        <Card.Body>
                          <Card.Text>
                            a) In line with PSR 040203, incremental date is 1st
                            January of every financial year provided the officer
                            has spent minimum of six months on the job in the
                            previous year i.e. Officer employed and resume duty
                            not later than July 1 of the previous year is
                            qualified for increment in January of the following
                            year.
                            <br/>
                            b) There is no provision for mid-year increment as
                            laid down in PSR.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="19">
                        Q19: I work in osun state, where will my tax be paid to
                        and would the IPPIS system apply the tax rate of osun
                        state in my salary
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="19">
                        <Card.Body>
                          <Card.Text>
                            a) Your tax is payable to the state where you work
                            and not necessarily the state where the headquarters
                            of your organization is situated. For example, if
                            you are employed by the federal ministry of
                            education and you are posted to federal inspectorate
                            of education in kebbi, your tax is payable to kebbi
                            state and to the account provided by the state
                            governor. The board have schedule of all payee to
                            enable you access your tax clearance certificate in
                            that state. The exception is the military, police
                            and the staff of the ministry of foreign affairs
                            whose tax by law is payable to the federal capital
                            territory.
                            <br/>
                            b) On the tax rate, all federal public servant tax
                            is approved by the federal Inland Revenue service.
                            You may approach the state office of FIRS to obtain
                            the rate applicable to your salary structure enable
                            you compare what is charged to your salary by IPPIS
                            Office.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="20">
                        Q20: I have additional qualification, how will I add
                        this to my record and would I be moved to the next grade
                        level with this?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="20">
                        <Card.Body>
                          <Card.Text>
                            a) The system will have opportunity for self-service
                            to enable you update some of your personal record
                            not under control like additional qualifications
                            etc.
                            <br/>
                            b) This additional qualification is not a promotion
                            or upgrading. If obtaining additional qualification
                            would lead to your upgrading, your IPPIS Role Player
                            in HR would handle this as appropriated.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="21">
                        Q21: How would promotion be reflected on the IPPIS
                        application and how will the arrears of such be paid?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="21">
                        <Card.Body>
                          <Card.Text>
                            Promotion is HR matter which would be handled by
                            your HR Role Player. However, all accruing arrears
                            would be tabulated and fun requested from the budget
                            office of the federation for the payment since
                            usually it is not in the current financial year
                            budget.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="22">
                        Q22: How is my pensioncontributiondeducted, remitted and
                        how would my employer (Government) add its own
                        counterpart contribution?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="22">
                        <Card.Body>
                          <Card.Text>
                            Your pension in applicable rate is rate is deducted
                            from your salary and at the same time, government
                            contribution is released through the budget office
                            of the federation and simultaneously credited to
                            your account via your PFA.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                    <Card>
                      <Accordion.Toggle as={Card.Header} eventKey="23">
                        Q23: I have just received my promotion after my
                        retirement, how would I be paid the arrears and enhance
                        my pension?
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey="23">
                        <Card.Body>
                          <Card.Text>
                            Your office will notify OAGF to re-enlist you for
                            the purpose of paying the promotion arrears and
                            placement. Only the arrears would be paid while all
                            statutory deduction made i.e. tax, pension, NHF.
                            Immediately you will be exited again from the
                            payroll.
                          </Card.Text>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog-sidebar right">
                <div class="single-blog-widget mt-0 mb-47">
                  <h3>what we do</h3>
                  <Card style={{width: "18rem"}}>
                    <Card.Body>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          Management of FGN employees records
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Payment of Salary and Wages to FGN employees
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Deductions of taxes and other third payments
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Remittance of payroll deductions to third party
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Enrolment of employees into IPPIS database
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </div>
                <div class="single-blog-widget mb-47">
                  <h3>Our Vision</h3>
                  <Card style={{width: "18rem"}}>
                    <Card.Body>
                      <Card.Text>
                        To have a centralised payroll system that meet the needs
                        of FGN employees and helps the Government to plan and
                        manage payroll budget by ensuring proper control of
                        personnel cost.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div class="single-blog-widget mb-47">
                  <h3>Our Core Values</h3>
                  <Card style={{width: "18rem"}}>
                    <Card.Body>
                      <ListGroup variant="flush">
                        <ListGroup.Item>Confidentiality</ListGroup.Item>
                        <ListGroup.Item>Individuality/empathy</ListGroup.Item>
                        <ListGroup.Item>Teamwork</ListGroup.Item>
                        <ListGroup.Item>Honesty</ListGroup.Item>
                        <ListGroup.Item>Accuracy/Timeliness</ListGroup.Item>
                        <ListGroup.Item>Trust/Accountability</ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
