import React, {Fragment} from 'react';
import Banner from "../components/banner/Banner";
import BannerImage from "../assets/img/banner/banner_new.jpg";
import {Card, ListGroup} from "react-bootstrap";

const HistoryPage = () => {
  return (
    <Fragment>
      <Banner title="FAQ" BannerImage={BannerImage}/>
      <div className="blog-details-area pt-100 pb-100">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="blog-details">
                <div className="blog-details-content">
                  <h1
                    style={{
                      fontSize: "30px",
                      color: "#303030",
                      fontWeight: "700",
                      fontFamily: "Open Sans, sans-serif",
                      textTransform: "uppercase",
                      marginBottom: "30px",
                      lineHeight: "26px"
                    }}
                  >
                    Our history
                  </h1>
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        <strong>Integrated Payroll and Personnel information system</strong> (IPPIS) was conceptualized
                        in October 2006 by the Federal Government as one of its Reform Programme, to improve the
                        effectiveness and efficiency in the storage of personnel records and administration of monthly
                        payroll in such a way to enhance confidence in staff emolument costs and budgeting.
                      </Card.Text>
                      <Card.Text>
                        It was also envisaged that the System will be implemented according to best practices obtainable
                        in other parts of the world where Information Communication Technology (ICT) is used to improve
                        management reporting.
                      </Card.Text>
                      <Card.Text>
                        The Pilot Phase implementation financed by the World Bank commenced in February 2006 at the
                        Bureau of Public Service Reforms (BPSR) upon the approval of the Federal Executive Council (FEC)
                      </Card.Text>
                      <Card.Text>
                        While the project went live in April 2007 with seven (7) Pilot MDAs, its management was
                        transferred to the Office of the Accountant General of the Federation (OAGF) in October 2008.
                        The Pilot MDAs are:
                        <ListGroup variant="flush">
                          <ListGroup.Item>Federal Ministry of Education;</ListGroup.Item>
                          <ListGroup.Item>Federal Ministry of Transportation (Works arm);</ListGroup.Item>
                          <ListGroup.Item>Federal Ministry of Finance</ListGroup.Item>
                          <ListGroup.Item>Budget Office of the Federation;</ListGroup.Item>
                          <ListGroup.Item>Federal Ministry of Information;</ListGroup.Item>
                          <ListGroup.Item> Ministry of Foreign Affairs; and</ListGroup.Item>
                          <ListGroup.Item>National Planning Commission.</ListGroup.Item>
                        </ListGroup>
                      </Card.Text>
                      <Card.Text>
                        Prior to their registration, the nominal rolls of the Seven Pilot MDAs submitted indicated
                        55,000 staff, hence World Bank paid for fifty-five thousand (55,000) licenses. However, after
                        their enrolment into the Scheme, it was discovered that their total staff strength was 32,000,
                        therefore additional eleven (11) MDAs were brought on board in July 2009 to optimize the 55,000
                        licenses purchased. The MDAs are:
                        <ListGroup variant="flush">
                          <ListGroup.Item>Federal Ministry of Transport;</ListGroup.Item>
                          <ListGroup.Item>Federal Ministry of Health;</ListGroup.Item>
                          <ListGroup.Item>Federal Ministry of Agriculture and Water Resources;</ListGroup.Item>
                          <ListGroup.Item>Federal Ministry of Petroleum Resources;</ListGroup.Item>
                          <ListGroup.Item>Federal Civil Service Commission;</ListGroup.Item>
                          <ListGroup.Item>Federal Ministry of Works (Housing & Urban Development);</ListGroup.Item>
                          <ListGroup.Item>Federal Ministry of Information (Communication);</ListGroup.Item>
                          <ListGroup.Item>Office of the Accountant-General of the Federation;</ListGroup.Item>
                          <ListGroup.Item>Office of the Head of Civil Service of the Federation;</ListGroup.Item>
                          <ListGroup.Item>Office of the Secretary to the Government of the Federation;</ListGroup.Item>
                        </ListGroup>
                      </Card.Text>
                      <Card.Text>
                        Having seen the benefits of the Scheme especially in the area of savings to the Government, the
                        Federal Executive Council in its meeting on Wednesday 1st December, 2010 approved the enrolment
                        of all MDAs that draw their Personnel Cost from the Consolidated Revenue Fund (CRF) into the
                        Integrated Payroll and Personnel Information System (IPPIS). The Phase II Service-wide
                        implementation commenced under the platform of a new Software called Oracle Application in
                        September 2011 in batches and is being financed by the Federal Government of Nigeria. As at
                        April 2018, 490 MDAs (including the Nigeria Police and Other Paramilitary Agencies) have been
                        enrolled into IPPISwith total staff strength of over 700,000 employees.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog-sidebar right">
                <div class="single-blog-widget mt-0 mb-47">
                  <h3>what we do</h3>
                  <Card style={{width: "18rem"}}>
                    <Card.Body>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          Management of FGN employees records
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Payment of Salary and Wages to FGN employees
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Deductions of taxes and other third payments
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Remittance of payroll deductions to third party
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Enrolment of employees into IPPIS database
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </div>
                <div class="single-blog-widget mb-47">
                  <h3>Our Vision</h3>
                  <Card style={{width: "18rem"}}>
                    <Card.Body>
                      <Card.Text>
                        To have a centralised payroll system that meet the needs
                        of FGN employees and helps the Government to plan and
                        manage payroll budget by ensuring proper control of
                        personnel cost.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div class="single-blog-widget mb-47">
                  <h3>Our Core Values</h3>
                  <Card style={{width: "18rem"}}>
                    <Card.Body>
                      <ListGroup variant="flush">
                        <ListGroup.Item>Confidentiality</ListGroup.Item>
                        <ListGroup.Item>Individuality/empathy</ListGroup.Item>
                        <ListGroup.Item>Teamwork</ListGroup.Item>
                        <ListGroup.Item>Honesty</ListGroup.Item>
                        <ListGroup.Item>Accuracy/Timeliness</ListGroup.Item>
                        <ListGroup.Item>Trust/Accountability</ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HistoryPage;
