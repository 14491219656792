import React from "react";
import { withRouter, Link } from "react-router-dom";

const {
  PUBLIC_URL,
  REACT_APP_IMAGE_URL,
  NODE_ENV,
  REACT_APP_IMAGE_LOCAL_URL,
} = process.env;

function EventItem({ id, title, subtitle, day, month, year, link, img }) {
  return (
    <div className="col-md-4 col-sm-6 col-xs-12">
      <div className="single-event mb-60">
        <div className="event-img">
          <Link to={`${PUBLIC_URL}/${link}/${id}`}>
            <img
              src={`${
                NODE_ENV === "production"
                  ? REACT_APP_IMAGE_URL
                  : REACT_APP_IMAGE_LOCAL_URL
              }${img}`}
              alt="event"
              crossOrigin="anonymous"
            />
            <div className="course-hover">
              <i className="fa fa-link" />
            </div>
          </Link>
          <div className="event-date">
            <h3>
              {day}{" "}
              <span>
                {month} <span>{year}</span>
              </span>
            </h3>
          </div>
        </div>
        <div className="event-content text-left">
          <h4>
            <Link to={`${PUBLIC_URL}/${link}/${id}`}>{title}</Link>
          </h4>
          <ul>
            <li>
              <span>event:</span>
              {subtitle.substr(0, 30)}...
            </li>
          </ul>
          <div className="event-content-right">
            <Link className="default-btn" to={`${PUBLIC_URL}/${link}/${id}`}>
              read more
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EventItem);
