import React, { Component, Fragment } from "react";
import SubscriptionForm from "./forms/SubscriptionForm";
import * as Yup from "yup";
import { connect } from "react-redux";
import { createSubscription, clearAlerts } from "../actions/frontend.action";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../components/loader/Loader";
import { getCsrf } from "../actions/frontend.action";

const userSchema = Yup.object().shape({
  email: Yup.string()
    .max(50, "Email address too long")
    .email("Email address is invalid")
    .required("Please provide email address"),
});
class SubscribeArea extends Component {
  componentDidMount() {
    this.props.getCsrf();
  }

  state = {
    initialValues: {
      email: "",
    },
  };
  onConfirm = () => {
    this.props.clearAlerts();
  };
  onCancel = () => {
    this.props.clearAlerts();
  };
  handleSubmit = (value) => {
    this.props.createSubscription(value, this.props.csrf);
  };
  render() {
    const {
      createdSubscription,
      createSubscriptionError,
      hasErrorCreatingSubscription,
      isCreatingSubscription,
      isCreatedSubscription,
    } = this.props;
    const { initialValues } = this.state;
    return (
      <Fragment>
        <div className="subscribe-area pt-60 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-12 mx-auto col-md-8 ">
                <div className="subscribe-content section-title text-center">
                  <h2>subscribe our newsletter</h2>
                  <p>
                    To keep you updated on latest events, news, and reports.
                  </p>
                </div>
                <div className="newsletter-form mc_embed_signup">
                  <SubscriptionForm
                    userSchema={userSchema}
                    handleSubmit={this.handleSubmit}
                    initialValues={initialValues}
                    isCreatingSubscription={isCreatingSubscription}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isCreatedSubscription && (
          <SweetAlert
            closeOnClickOutside={true}
            success
            title="Data saved!"
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
            timeout={5000}
            confirmBtnBsStyle="success"
          >
            <span className="text-success">{createdSubscription}</span>
          </SweetAlert>
        )}
        {hasErrorCreatingSubscription && (
          <SweetAlert
            closeOnClickOutside={true}
            danger
            title="Error!"
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
            confirmBtnBsStyle="danger"
            btnSize="sm"
          >
            <span className="text-danger">{createSubscriptionError}</span>
          </SweetAlert>
        )}
        <Loader showLoader={isCreatingSubscription} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isCreatingSubscription: state.frontendReducer.isCreatingSubscription,
  isCreatedSubscription: state.frontendReducer.isCreatedSubscription,
  hasErrorCreatingSubscription:
    state.frontendReducer.hasErrorCreatingSubscription,
  createSubscriptionError: state.frontendReducer.createSubscriptionError,
  createdSubscription: state.frontendReducer.createdSubscription,
  csrf: state.frontendReducer._csrf,
});

const mapActionsToProps = { createSubscription, clearAlerts, getCsrf };

export default connect(mapStateToProps, mapActionsToProps)(SubscribeArea);
