import {combineReducers} from "redux";
import authenticationReducer from "./authentication.reducer";
import frontendReducer from "./frontend.reducer";
import socketReducer from './socket.reducer'

const rootReducer = combineReducers({
  authenticationReducer,
  frontendReducer,
  socketReducer
});

export default rootReducer;
