import React from 'react';
import FooterMain from "./FooterMain";
import FooterBottom from "./FooterBottom";

function Footer() {
    return (
        <footer className="footer-area" style={{backgroundColor:'#ebeef3'}}>
            <FooterMain/>
            <FooterBottom/>
        </footer>
    )
}

export default Footer;