import React, {Component, Fragment} from "react";
import Banner from "../components/banner/Banner";
import BannerImage from "../assets/img/banner/banner_new.jpg";
import {connect} from "react-redux";
import EventDetail from "../components/EventDetail";

class EventDetailsPage extends Component {
  state = {
    isFetchingPosts: false,
    isFetchedPosts: false,
    fetchingPostsError: false,
    postsError: "",
    posts: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps || nextProps !== undefined) {
      if (nextProps.isFetchedPosts !== prevState.isFetchedPosts) {
        return {posts: nextProps.posts}
      }
    }
    return null;
  }

  render() {
    const {posts} = this.state;
    const findPost = posts.find(
      (event) => event.id === this.props.match.params.pageId
    );
    const check = findPost === undefined ? '' : findPost;
    return (
      <Fragment>
        <Banner title={check.title} BannerImage={BannerImage}/>
        <EventDetail event={check} posts={posts}/>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  const {
    frontendReducer: {
      isFetchingPosts,
      isFetchedPosts,
      fetchingPostsError,
      postsError,
      posts,
    }
  } = state;
  return {
    isFetchingPosts,
    isFetchedPosts,
    fetchingPostsError,
    postsError,
    posts,
  }
};
export default connect(mapStateToProps)(EventDetailsPage)
