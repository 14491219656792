import React, { Component, createRef, Fragment } from "react";
import Banner from "../components/banner/Banner";
import ChatForm from "../components/forms/ChatForm";
import * as Yup from "yup";
import BannerImage from "../assets/img/banner/banner_new.jpg";

const chatSchema = Yup.object().shape({
  username: Yup.string()
    .max(20, "Name is too long")
    .required("Please provide username"),
  email: Yup.string()
    .max(25, "Email too long")
    .email("Invalid email address")
    .required("Please provide email"),
});

class LiveChatPage extends Component {
  state = {
    initialValues: {
      username: "",
      email: "",
    },
    url: "",
    milliseconds: "",
    displayChatModal: false,
  };

  setModalShow = (status) => this.setState({ displayChatModal: status });

  brand = createRef();
  lang = createRef();

  handleSubmit = (values) => {
    const {
      REACT_APP_CHAT_TENANT,
      REACT_APP_CHAT_CLIENT_TYPE,
      REACT_APP_CHAT_URL,
    } = process.env;
    const { username, email } = values;
    const lang = this.lang.current.value;
    const brand = this.brand.current.value;
    const milliseconds = new Date().getTime();
    const flag = new Date().getTime();
    const attachedData = JSON.stringify({
      CHATTYPE: brand,
      REGNUM: "111222333",
      CUSTOMERNAME: "Tom Smith",
    });
    let url = `${REACT_APP_CHAT_URL}?realm=${REACT_APP_CHAT_TENANT}&lang=${lang}&clientType=${REACT_APP_CHAT_CLIENT_TYPE}&userName=${username}&flag=${flag}&userEmail=${email}&attachedData=${attachedData}`;
    document.open(
      url,
      `ippis-chat ${milliseconds}`,
      "width=320px,height=530px"
    );
  };

  render() {
    const { initialValues } = this.state;
    return (
      <Fragment>
        <Banner title="Live Chat" BannerImage={BannerImage} />
        <div className="login-area pt-150 pb-150">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-8 col-lg-6 col-sm-4 mx-auto text-center">
                <div className="login">
                  <div className="login-form-container">
                    <div className="login-text">
                      <h2>Chat with our agents</h2>
                      <span>
                        please provide your name and email and address
                      </span>
                    </div>
                    <div className="login-form">
                      <ChatForm
                        initialValues={initialValues}
                        chatSchema={chatSchema}
                        handleSubmit={this.handleSubmit}
                        lang={this.lang}
                        brand={this.brand}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default LiveChatPage;
