import React from "react";
import { Formik, Form, Field } from "formik";

export default function ContactForm({
  initialValues,
  handleSubmit,
  contactSchema,
}) {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values);
        setSubmitting(false);
        resetForm(values);
      }}
      validationSchema={contactSchema}
    >
      {({ errors, touched, handleChange, handleBlur, values }) => (
        <Form>
          <div className="form-group row col-md-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="mdi mdi-account" />
                </span>
              </div>
              <Field
                className="form-control"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="Full Name"
                name="name"
              />
            </div>
            {errors.name && touched.name && (
              <div className="mt-2">
                <span className="text-danger">{errors.name}</span>
              </div>
            )}
          </div>
          <div className="form-group row col-md-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="mdi mdi-email" />
                </span>
              </div>
              <Field
                value={values.email}
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="Email"
                name="email"
              />
            </div>
            {errors.email && touched.email && (
              <div className="mt-2">
                <span className="text-danger">{errors.email}</span>
              </div>
            )}
          </div>
          <div className="form-group row col-md-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="zmdi zmdi-account-box-mail" />
                </span>
              </div>
              <select
                className="select2 form-control form-control-sm custom-select"
                onChange={handleChange}
                onBlur={handleBlur}
                name="subject"
                value={values.subject}
              >
                <option value="0" className="font-weight-bold">
                  --Subject--
                </option>
                <option value="Update of Personal record">
                  Update of Personal record
                </option>
                <option value="Correction of Employment history">
                  Correction of Employment history
                </option>
                <option value="Promotion Arrears">Promotion Arrears</option>
                <option value="Allowances">Allowances</option>
                <option value="Variation">Variation</option>
                <option value="PAYE Tax Deduction">PAYE Tax Deduction</option>
                <option value="NHIS Deduction">NHIS Deduction</option>
                <option value="National Housing Fund Deduction">
                  National Housing Fund Deduction
                </option>
                <option value="Pension Fund Administrator Deduction">
                  Pension Fund Administrator Deduction
                </option>
                <option value="Cooperative Societies Deduction">
                  Cooperative Societies Deduction
                </option>
                <option value="Trade Unions/Association Dues">
                  Trade Unions/Association Dues
                </option>
                <option value="Bank Loans">Bank Loans</option>
                <option value="System Queries">System Queries</option>
              </select>
            </div>
            {errors.subject && touched.subject && (
              <div className="mt-2">
                <span className="text-danger">{errors.subject}</span>
              </div>
            )}
          </div>
          <div className="form-group row col-md-12">
            <p>
              Message{" "}
              {errors.message && touched.message && (
                <span className="text-danger">{errors.message}</span>
              )}
            </p>
            <textarea
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              className="form-control"
              name="message"
              cols="15"
              rows="10"
            />
          </div>
          <button type="submit" className="btn btn-outline-success">
            <span>send message</span>
          </button>
        </Form>
      )}
    </Formik>
  );
}
