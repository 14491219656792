import React from "react";
import { render } from "react-dom";
import "./assets/custom/bootstrap.min.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/animate.css";
import "./assets/css/meanmenu.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/et-line-icon.css";
import "./assets/css/ionicons.min.css";
import "./assets/css/material-design-iconic-font.min.css";
import "./assets/css/icons.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

import App from "./App";
import { Provider } from "react-redux";
import storeConfig from "./store/store.config";

render(
  <Provider store={storeConfig}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// import App from './test/App.test';
//
// render(<App/>,document.getElementById('root'));
