import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import "./style.css";
// import Loader from "../loader/Loader";

const {
  PUBLIC_URL,
  REACT_APP_IMAGE_URL,
  NODE_ENV,
  REACT_APP_IMAGE_LOCAL_URL,
} = process.env;

function LandingPageSlider({ posts, isFetchedPosts }) {
  let getPostsForSlider = [];
  if (isFetchedPosts) {
    getPostsForSlider = posts.filter((post) => post.withSlider === true);
  }

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <section id="slider-container" className="slider-area two">
      <Carousel
        className="slider-owl owl-theme owl-carousel"
        activeIndex={index}
        onSelect={handleSelect}
      >
        {!getPostsForSlider || getPostsForSlider.length !== 0
          ? getPostsForSlider.map((event) => (
              <Carousel.Item className="single-slide item" key={event.id}>
                <img
                  className="d-block w-100"
                  src={`${
                    NODE_ENV === "production"
                      ? REACT_APP_IMAGE_URL
                      : REACT_APP_IMAGE_LOCAL_URL
                  }${event.img}`}
                  alt={`slider-${event.id}`}
                  crossOrigin="anonymous"
                />
                <Carousel.Caption className="slider-content-area">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-10 mx-auto ">
                        <div className="slide-content-wrapper">
                          <div className="slide-content text-center">
                            <h2>{event.title}</h2>
                            <p>{event.subtitle}</p>
                            <Link
                              className="default-btn"
                              to={`${PUBLIC_URL}/event-details/${event.id}`}
                            >
                              Learn more
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            ))
          : []}
      </Carousel>
    </section>
  );
}

const mapStateToProps = (state) => {
  const {
    frontendReducer: {
      isFetchingPosts,
      isFetchedPosts,
      fetchingPostsError,
      postsError,
      posts,
    },
  } = state;
  return {
    isFetchingPosts,
    isFetchedPosts,
    fetchingPostsError,
    postsError,
    posts,
  };
};
export default withRouter(connect(mapStateToProps)(LandingPageSlider));
