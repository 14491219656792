import React from 'react';
import HeaderTop from "./HeaderTop";
import HeaderStickyArea from "./HeaderStickyArea";

function Header() {
    return (
        <header className="top">
            <HeaderTop/>
            <HeaderStickyArea/>
        </header>
    )
}

export default Header;