const about = `<p>IPPIS Secretariat is a Department under the Office of the Accountant-General of the Federation responsible for payment of salaries and wages directly to Government employee’s bank account with appropriate deductions and remittances of 3rd party payments such as; Federal Inland Revenue Service, State Boards of Internal Revenue, National Health Insurance Scheme, National Housing Fund, Pension Fund Administrator, Cooperative Societies, Trade Unions Dues, Association Dues and Bank Loans.</p><p>We are committed to efficient and effective service delivery. Our main aim is to pay accurately and on time within statutory and contractual regulations. We aim to provide a payroll service that is customer focused and that utilises technology wherever possible.</p><p>There are 696 MDAS on IPPIS Platform as at June, 2020. The department is responsible for processing and payment of salary to over One million (1,139,633) Federal Government Employees across the 696 MDAs. IPPIS aim is to enrol into the platform, all Federal Government MDAs that draws personnel cost fund from the Consolidated Revenue Fund. Since inception of the IPPIS project in April 2007, the department have saved the Federal Government of Nigeria billions of Naira by eliminating thousands of ghost workers.</p>`;
const management = `<p style="text-align:start;"><span style="color: rgb(91,98,107);background-color: rgb(255,255,255);font-size: 14px;font-family: Roboto, sans-serif;">IPPIS Department carries out its functions in collaboration with; the Federal Ministry of Finance, Office of the Head of Service, Civil Service Commission, Office of the Auditor General of the Federation and MDAs that are enrolled on IPPIS.</span></p><p style="text-align:start;"></p><p style="text-align:left;"><span style="color: rgb(91,98,107);background-color: rgb(255,255,255);font-size: 14px;font-family: Roboto, sans-serif;">The Acting Director IPPIS, Dr. Nsikak Ben, (FCA) is responsible for the overall Management of IPPIS Department, reporting to the Accountant-General of the Federation.</span>&nbsp;</p>`;

const {
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_SOCKET_URL,
  REACT_APP_SOCKET_LOCAL_URL,
  REACT_APP_API_LOCAL_URL,
} = process.env;

const config = {
  urlConfig: () => {
    if (NODE_ENV === "production") {
      return {
        url: `${REACT_APP_API_URL}`,
        socketUrl: `${REACT_APP_SOCKET_URL}`,
      };
    }
    return {
      url: `${REACT_APP_API_LOCAL_URL}`,
      socketUrl: `${REACT_APP_SOCKET_LOCAL_URL}`,
    };
  },
  about,
  management,
};

export default config;
