import React from "react";
import {withRouter} from "react-router-dom";
import Events from "./Events";
import ReactHtmlParser from 'react-html-parser';

function EventDetail(
  {
    event: {id, title, subtitle, day, month, year, link, img, description},
    posts
  }
) {
  const filterEvents = posts.filter((event) => event.id !== id);
  return (
    <div className="event-details-area blog-area pt-150 pb-140">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="event-details">
              <div className="event-details-img">
                <img src={`${process.env.REACT_APP_IMAGE_URL}${img}`} width="100%" alt="event-detail"
                     crossOrigin="anonymous"/>
                <div className="event-date">
                  <h3>
                    {day}
                    <span>
                      {month}<span>{year}</span>
                    </span>
                  </h3>
                </div>
              </div>
              <div className="event-details-content">
                <h2>{title}</h2>
                <ul>
                  <li>
                    <span>event : </span>
                    {subtitle}
                  </li>
                </ul>
                {ReactHtmlParser(description)}
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="blog-sidebar right">
              <div className="single-blog-widget mb-50">
                <h3>latest post</h3>
                {filterEvents.map((event) => (
                  <Events key={event.id} event={event}/>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(EventDetail);
