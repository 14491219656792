import React, { Component, Fragment } from "react";
import EventGallery from "../components/EventGallery";
import Banner from "../components/banner/Banner";
import BannerImage from "../assets/img/banner/banner_new.jpg";
export default class NewsAndEventsPage extends Component {
  render() {
    return (
      <Fragment>
        <Banner title="News and Events" BannerImage={BannerImage} />
        <EventGallery />;
      </Fragment>
    );
  }
}
