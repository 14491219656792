import React from "react";
import { withRouter, Link } from "react-router-dom";

const url = process.env.PUBLIC_URL;

function HeaderTop() {
  return (
    <div className="header-top">
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-8 col-xs-12">
            <div className="header-top-left">
              <p>HAVE ANY QUESTION ? +234 908 700 573 5</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-4 col-xs-12">
            <div className="header-top-right text-right">
              <ul>
                <li>
                  <Link to={`${url}/support`}>Help Desk</Link>
                </li>
                <li>
                  <Link to={`${url}/support`}>FAQ</Link>
                </li>
                <li>
                  <a target='_blank' href="https://ippis.gov.ng:2096">Webmail</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(HeaderTop);
