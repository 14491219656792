import React, { Fragment } from "react";

export default function ErrorPage() {
  return (
    <Fragment>
      <div className="wrapper-page">
        <div className="card">
          <div className="card-block">
            <div className="ex-page-content text-center">
              <h1 className="">404!</h1>
              <h3 className="">Sorry, page not found</h3>
              <br />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
