import React from "react";
import { withRouter, Link } from "react-router-dom";
import SearchForm from "./forms/SearchForm";
import Logo from "../assets/img/logo/ippis-logo-new.png";

const url = process.env.PUBLIC_URL;

function HeaderStickyArea() {
  return (
    <div className="header-area two header-sticky ">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-sm-3 col-xs-6">
            <div className="logo">
              <Link to={`${url}/`}>
                <img src={Logo} alt="logo" />
              </Link>
            </div>
          </div>
          <div className="col-md-9 col-sm-9 col-xs-6">
            <div className="content-wrapper text-right">
              {/*<!-- Main Menu Start -->*/}
              <div className="main-menu">
                <nav>
                  <ul>
                    <li>
                      <Link to={`${url}/`}>Home</Link>
                    </li>
                    <li>
                      <Link to={`${url}/about`}>About</Link>
                    </li>

                    <li>
                      <a target="_blank" rel="noreferrer" href="https://registration.ippis.gov.ng/">Enrolment</a>
                    </li>
                    <li>
                      <Link to={`${url}/events`}>News & Events</Link>
                      <ul>
                        <li>
                          <Link to={`${url}/events`}>News</Link>
                        </li>
                        {/*<li>*/}
                        {/*<Link to={`${url}/events`}>Media</Link>*/}
                        {/*</li>*/}
                      </ul>
                    </li>
                    <li>
                      <Link to={`${url}/complaint`}>Complaint</Link>
                    </li>
                    <li>
                      <Link to={`${url}/contact`}>Contact</Link>
                    </li>
                    <li>
                      <Link to={`${url}/chat`}>Live Chat</Link>
                    </li>
                  </ul>
                </nav>
              </div>
              {/*<!--Search Form Start-->*/}
              <div className="search-btn">
                <ul
                  data-toggle="dropdown"
                  className="header-search search-toggle"
                >
                  <li className="search-menu">
                    <i className="fa fa-search" />
                  </li>
                </ul>
                <div className="search">
                  <div className="search-form">
                    <SearchForm />
                  </div>
                </div>
              </div>
              {/*<!--End of Search Form-->*/}
              {/*<!-- Main Menu End -->*/}
            </div>
          </div>
          <div className="col-xs-12">
            <div className="mobile-menu hidden-lg hidden-md hidden-sm" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(HeaderStickyArea);
