import React, { Fragment } from "react";
import Banner from "../components/banner/Banner";
import { Container, Jumbotron } from "react-bootstrap";
import BannerImage from "../assets/img/banner/banner_new.jpg";
export default function ComingSoonPage() {
  return (
    <Fragment>
      <Banner title="News and Events" BannerImage={BannerImage} />
      <Jumbotron fluid>
        <Container>
          <div className="text-center">
            <h1
              style={{
                fontSize: "50px",
                lineHeight: "90px",
                textTransform: "uppercase",
              }}
            >
              Under Contruction
            </h1>
            <p>This page is under heavy construction.</p>
          </div>
        </Container>
      </Jumbotron>
    </Fragment>
  );
}
