import {NEW_CASE_FROM_WEB_CREATED, NOTIFICATION_ERROR} from "../constants/socket.constant";

const initialize = {
  data: [],
  error: {}
};

const socketReducer = (state = initialize, {type, payload}) => {
  switch (type) {
    case NEW_CASE_FROM_WEB_CREATED:
      state = {
        ...state,
        data: payload
      };
      break;
    case NOTIFICATION_ERROR:
      state = {
        ...state,
        error: payload
      };
      break;
    default:
      state = {...state}
  }

  return state;
};

export default socketReducer;