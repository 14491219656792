import React from "react";
import { Formik, Form, Field } from "formik";
import { Button } from "react-bootstrap";

export default function ChatForm({
  handleSubmit,
  initialValues,
  chatSchema,
  lang,
  brand,
}) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={chatSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        handleSubmit(values);
        setSubmitting(false);
        resetForm(values);
      }}
    >
      {({ errors, touched, handleBlur, handleChange }) => (
        <Form>
          <input type="hidden" ref={lang} name="lang" value="EN" />
          <input type="hidden" ref={brand} name="brand" value="chat" />
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="mdi mdi-account" />
                </span>
              </div>
              <Field
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="username"
                className="form-control"
                placeholder="Name"
              />
            </div>
            {errors.username && touched.username && (
              <div className="mt-2">
                <span className="text-danger">{errors.username}</span>
              </div>
            )}
          </div>
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="mdi mdi-email" />
                </span>
              </div>
              <Field
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                name="email"
                className="form-control"
                placeholder="Email"
              />
            </div>
            {errors.email && touched.email && (
              <div className="mt-2">
                <span className="text-danger">{errors.email}</span>
              </div>
            )}
          </div>
          <div className="form-group">
            <Button type="submit" variant="success" size="lg" block>
              Start Chat
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
