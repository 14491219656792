import React, { Component, Fragment } from "react";
import Banner from "../components/banner/Banner";
import CaseForm from "../components/forms/CaseForm";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { createCase, clearAlerts } from "../actions/frontend.action";

import BannerImage from "../assets/img/banner/banner_new.jpg";
import Loader from "../components/loader/Loader";
import { getCsrf } from "../actions/frontend.action";

class ComplaintFormPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingState: false,
      isFetchedStates: false,
      hasError: false,
      states: [],
      error: "",
      displayIssueType: false,
      displayOtherIssueType: false,
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      ippisNumber: "",
      dob: "",
      gender: "",
      location: "",
      caseCategory: "",
      caseType: "",
      otherType: "",
      organization: "",
      errors: {},
      resetCaseTypeSelected: false,
      showEscalateButton: false,
      showResolveButton: false,
      displayCallerModal: false,
      isFetchingKnowledgeBase: false,
      isFetchedKnowledgeBase: false,
      fetchingKnowledgeBaseError: false,
      knowledgeBaseError: "",
      knowledgeBase: [],
      enquiryKnowledgeBase: "",
      complaintKnowledgeBase: {},
      isSubmitResolveButtonOpen: false,
      selectedOption: "",
      case: [],
    };
    this.caseRef = React.createRef();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps) {
      if (
        nextProps.isFetchingState !== prevState.isFetchingState ||
        nextProps.isFetchedStates !== prevState.isFetchedStates ||
        nextProps.hasError !== prevState.hasError ||
        nextProps.isFetchingKnowledgeBase !==
          prevState.isFetchingKnowledgeBase ||
        nextProps.isFetchedKnowledgeBase !== prevState.isFetchedKnowledgeBase ||
        nextProps.fetchingKnowledgeBaseError !==
          prevState.fetchingKnowledgeBaseError
      ) {
        return {
          isFetchingState: nextProps.isFetchingState,
          isFetchedStates: nextProps.isFetchedStates,
          hasError: nextProps.hasError,
          states: nextProps.states,
          error: nextProps.error,
          knowledgeBaseError: nextProps.knowledgeBaseError,
          knowledgeBase: nextProps.knowledgeBase,
        };
      }
    }
    return null;
  }

  componentDidMount() {
    this.props.getCsrf();
  }

  toggleComplaintField = (e) => {
    const {
      displayIssueType,
      displayOtherIssueType,
      showEscalateButton,
      showResolveButton,
      errors,
    } = this.state;
    if (e.currentTarget.name === "caseCategory" && errors.touched) {
      this.setState({ errors: { caseCategory: "", touched: false } });
    }
    if (
      (e.target.value === "" && displayIssueType) ||
      (e.target.value === "" && displayOtherIssueType) ||
      (e.target.value === "" && showEscalateButton) ||
      (e.target.value === "" && showResolveButton)
    ) {
      this.setState({
        displayIssueType: false,
        otherType: "",
        caseType: "0",
        displayOtherIssueType: false,
        showEscalateButton: false,
        showResolveButton: false,
        [e.target.name]: e.target.value,
      });
      return;
    }
    if (
      (e.target.value !== "0" && displayIssueType) ||
      (e.target.value !== "0" && displayOtherIssueType) ||
      (e.target.value !== "0" && showEscalateButton) ||
      (e.target.value !== "0" && showResolveButton)
    ) {
      this.setState({
        displayIssueType: false,
        otherType: "",
        caseType: "0",
        displayOtherIssueType: false,
        showEscalateButton: false,
        showResolveButton: false,
      });
    }
    if (this.caseRef.current.value === "6") {
      this.resetCaseType();
    }
    this.setState({ displayIssueType: true, [e.target.name]: e.target.value });
  };

  resetCaseType = () => {
    this.setState({ caseType: "0" });
  };

  toggleOtherComplaintField = (e) => {
    const {
      displayOtherIssueType,
      errors,
      showEscalateButton,
      showResolveButton,
    } = this.state;
    if (e.currentTarget.name === "caseType" && errors.touched) {
      this.setState({ errors: { caseType: "", touched: false } });
    }

    if (e.currentTarget.name === "caseType" && e.currentTarget.value === "0") {
      if (showResolveButton && showEscalateButton) {
        this.setState({
          showResolveButton: false,
          showEscalateButton: false,
          otherType: "",
        });
      }
    } else if (
      e.currentTarget.name === "caseType" &&
      e.currentTarget.value !== "0"
    ) {
      this.setState({ showResolveButton: true, showEscalateButton: true });
    }

    if (e.target.value === "6") {
      this.setState({ displayOtherIssueType: true });
    } else {
      if (displayOtherIssueType) {
        this.setState({ displayOtherIssueType: !displayOtherIssueType });
      }
    }

    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      phone,
      dob,
      location,
      caseCategory,
      caseType,
      otherType,
      ippisNumber,
      organization,
      gender,
      displayOtherIssueType,
      selectedOption,
    } = this.state;

    if (firstName === "") {
      this.setState({
        errors: { firstName: "Field First Name is required", touched: true },
      });
    } else if (lastName === "") {
      this.setState({
        errors: { lastName: "Field Last Name is required", touched: true },
      });
    } else if (phone === "") {
      this.setState({
        errors: { phone: "Field Phone Number is required", touched: true },
      });
    } else if (ippisNumber === "") {
      this.setState({
        errors: {
          ippisNumber: "Field IPPIS Number is required",
          touched: true,
        },
      });
    } else if (gender === "") {
      this.setState({
        errors: { gender: "Field Gender is required", touched: true },
      });
    } else if (organization === "") {
      this.setState({
        errors: {
          organization: "Field Organization is required",
          touched: true,
        },
      });
    } else if (location === "") {
      this.setState({
        errors: { location: "Field Location is required", touched: true },
      });
    } else if (caseCategory === "") {
      this.setState({
        errors: {
          caseCategory: "Field Case Category is required",
          touched: true,
        },
      });
    } else if (caseType === "0" || caseType === "") {
      this.setState({
        errors: { caseType: "Field Case Type is required", touched: true },
      });
    } else if (displayOtherIssueType && otherType === "") {
      this.setState({
        errors: { otherType: "Field Other Issues is required", touched: true },
      });
    } else {
      let data = {
        firstName,
        lastName,
        email,
        phone,
        dob,
        gender,
        location,
        caseCategory,
        caseType,
        otherType,
        ippisNumber,
        organization,
        caseStatus: selectedOption,
      };

      this.props.createCase(data, this.props.csrf);
    }
  };

  handleInputChange = (e) => {
    const { errors } = this.state;
    if (e.currentTarget.name === "firstName" && errors.touched) {
      this.setState({ errors: { firstName: "", touched: false } });
    }
    if (e.currentTarget.name === "lastName" && errors.touched) {
      this.setState({ errors: { lastName: "", touched: false } });
    }
    if (e.currentTarget.name === "email" && errors.touched) {
      this.setState({ errors: { email: "", touched: false } });
    }
    if (e.currentTarget.name === "phone" && errors.touched) {
      this.setState({ errors: { phone: "", touched: false } });
    }
    if (e.currentTarget.name === "ippisNumber" && errors.touched) {
      this.setState({ errors: { ippisNumber: "", touched: false } });
    }
    if (e.currentTarget.name === "dob" && errors.touched) {
      this.setState({ errors: { dob: "", touched: false } });
    }
    if (e.currentTarget.name === "gender" && errors.touched) {
      this.setState({ errors: { gender: "", touched: false } });
    }
  };

  static validateEmail = (email) => {
    const expression = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return expression.test(String(email).toLowerCase());
  };

  handleChange = (e) => {
    const { errors } = this.state;
    if (e.currentTarget.name === "email") {
      errors.email = ComplaintFormPage.validateEmail(e.currentTarget.value)
        ? ""
        : "invalid email address";
      errors.touched = true;
      this.setState({ errors });
    }
    if (e.currentTarget.name === "location" && errors.touched) {
      this.setState({ errors: { location: "", touched: false } });
    }
    if (e.currentTarget.name === "otherType" && errors.touched) {
      this.setState({ errors: { otherType: "", touched: false } });
    }
    if (e.currentTarget.name === "organization" && errors.touched) {
      this.setState({ errors: { organization: "", touched: false } });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  resolveCase = (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      phone,
      dob,
      location,
      caseCategory,
      caseType,
      otherType,
      ippisNumber,
      organization,
      gender,
      displayOtherIssueType,
    } = this.state;

    if (firstName === "") {
      this.setState({
        errors: { firstName: "Field First Name is required", touched: true },
      });
    } else if (lastName === "") {
      this.setState({
        errors: { lastName: "Field Last Name is required", touched: true },
      });
    } else if (email === "") {
      this.setState({
        errors: { email: "Field Email is required", touched: true },
      });
    } else if (phone === "") {
      this.setState({
        errors: { phone: "Field Phone Number is required", touched: true },
      });
    } else if (ippisNumber === "") {
      this.setState({
        errors: {
          ippisNumber: "Field IPPIS Number is required",
          touched: true,
        },
      });
    } else if (dob === "") {
      this.setState({
        errors: { dob: "Field Date of Birth is required", touched: true },
      });
    } else if (gender === "") {
      this.setState({
        errors: { gender: "Field Gender is required", touched: true },
      });
    } else if (organization === "") {
      this.setState({
        errors: {
          organization: "Field Organization is required",
          touched: true,
        },
      });
    } else if (location === "") {
      this.setState({
        errors: { location: "Field Location is required", touched: true },
      });
    } else if (caseCategory === "") {
      this.setState({
        errors: {
          caseCategory: "Field Case Category is required",
          touched: true,
        },
      });
    } else if (caseType === "0" || caseType === "") {
      this.setState({
        errors: { caseType: "Field Case Type is required", touched: true },
      });
    } else if (displayOtherIssueType && otherType === "") {
      this.setState({
        errors: { otherType: "Field Other Issues is required", touched: true },
      });
    } else {
      this.setModalShow(true);
    }
  };

  onConfirm = () => {
    this.props.clearAlerts();
    if (!this.props.createCaseHasError) {
      this.setState({
        displayCallerModal: false,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        dob: "",
        gender: "",
        location: "",
        caseCategory: "",
        caseType: "",
        otherType: "",
        ippisNumber: "",
        organization: "",
        showEscalateButton: false,
      });
    }
    this.setState({
      displayCallerModal: false,
      showEscalateButton: false,
    });
  };

  setModalShow = (status) => this.setState({ displayCallerModal: status });

  onCancel = () => {
    this.props.clearAlerts();
    this.setState({ displayCallerModal: false, showEscalateButton: false });
  };

  render() {
    const {
      states,
      displayIssueType,
      displayOtherIssueType,
      errors,
      showEscalateButton,
      showResolveButton,
    } = this.state;
    return (
      <Fragment>
        <Banner title="Complaint" BannerImage={BannerImage} />
        <div className="login-area pt-150 pb-150">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-10 col-lg-9 col-sm-6 mx-auto text-center">
                <div className="login">
                  <div className="login-form-container">
                    <div className="login-text">
                      <h2>Complaint form</h2>
                      <span>
                        Please fill the form to lodge a complaint. Note that all
                        fields are required.
                      </span>
                    </div>
                    <div className="login-form">
                      <CaseForm
                        states={states}
                        toggleComplaintField={this.toggleComplaintField}
                        displayIssueType={displayIssueType}
                        toggleOtherComplaintField={
                          this.toggleOtherComplaintField
                        }
                        displayOtherIssueType={displayOtherIssueType}
                        onSubmit={this.onSubmit}
                        errors={errors}
                        handleChange={this.handleChange}
                        initialValues={this.state}
                        handleInputChange={this.handleInputChange}
                        caseRef={this.caseRef}
                        showEscalateButton={showEscalateButton}
                        showResolveButton={showResolveButton}
                        resolveCase={this.resolveCase}
                      />
                      {this.props.isCreatedCase && (
                        <SweetAlert
                          closeOnClickOutside={true}
                          success
                          title="Data saved!"
                          onConfirm={this.onConfirm}
                          onCancel={this.onCancel}
                          timeout={5000}
                          confirmBtnBsStyle="success"
                        >
                          <span className="text-success">
                            {this.props.case}
                          </span>
                        </SweetAlert>
                      )}
                      {this.props.createCaseHasError && (
                        <SweetAlert
                          closeOnClickOutside={true}
                          danger
                          title="Error!"
                          onConfirm={this.onConfirm}
                          onCancel={this.onCancel}
                          confirmBtnBsStyle="danger"
                        >
                          <span className="text-danger">
                            {this.props.createCaseError}
                          </span>
                        </SweetAlert>
                      )}
                      <Loader showLoader={this.props.isCreatingCase} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isFetchingState: state.frontendReducer.isFetchingState,
  isFetchedStates: state.frontendReducer.isFetchedStates,
  isFetchingKnowledgeBase: state.frontendReducer.isFetchingKnowledgeBase,
  isFetchedKnowledgeBase: state.frontendReducer.isFetchedKnowledgeBase,
  fetchingKnowledgeBaseError: state.frontendReducer.fetchingKnowledgeBaseError,
  knowledgeBaseError: state.frontendReducer.knowledgeBaseError,
  knowledgeBase: state.frontendReducer.knowledgeBase,
  hasError: state.frontendReducer.hasError,
  states: state.frontendReducer.states,
  error: state.frontendReducer.error,
  case: state.frontendReducer.case,
  createCaseHasError: state.frontendReducer.createCaseHasError,
  createCaseError: state.frontendReducer.createCaseError,
  isCreatedCase: state.frontendReducer.isCreatedCase,
  isCreatingCase: state.frontendReducer.isCreatingCase,
  csrf: state.frontendReducer._csrf,
});

export default connect(mapStateToProps, {
  createCase,
  clearAlerts,
  getCsrf,
})(ComplaintFormPage);
