import React, { Component } from "react";
import EventItem from "./EventItem";
import { connect } from "react-redux";

class EventGallery extends Component {
  state = {
    isFetchingPosts: false,
    isFetchedPosts: false,
    fetchingPostsError: false,
    postsError: "",
    posts: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps || nextProps !== undefined) {
      if (nextProps.isFetchedPosts !== prevState.isFetchedPosts) {
        return { posts: nextProps.posts };
      }
    }
    return null;
  }

  render() {
    const { posts } = this.state;
    const { landingPage } = this.props;
    if (landingPage) {
      return (
        <div className="event-area three text-center pt-60 pb-60">
          <div className="container">
            <div className="row">
              {posts.slice(0, 3).map((event) => (
                <EventItem
                  key={event.id}
                  id={event.id}
                  title={event.title}
                  subtitle={event.subtitle}
                  day={event.day}
                  month={event.month}
                  year={event.year}
                  link={event.link}
                  img={event.img}
                />
              ))}
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="event-area three text-center pt-60 pb-60">
          <div className="container">
            <div className="row">
              {posts.map((event) => (
                <EventItem
                  key={event.id}
                  id={event.id}
                  title={event.title}
                  subtitle={event.subtitle}
                  day={event.day}
                  month={event.month}
                  year={event.year}
                  link={event.link}
                  img={event.img}
                />
              ))}
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  const {
    frontendReducer: {
      isFetchingPosts,
      isFetchedPosts,
      fetchingPostsError,
      postsError,
      posts,
    },
  } = state;
  return {
    isFetchingPosts,
    isFetchedPosts,
    fetchingPostsError,
    postsError,
    posts,
  };
};
export default connect(mapStateToProps)(EventGallery);
