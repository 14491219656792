export const USER_GETTING_OTP = "auth:USER_GETTING_OTP";
export const USER_GET_OTP = "auth:USER_GET_OTP";
export const USER_ERROR_GETTING_OTP = "auth:USER_ERROR_GETTING_OTP";

export const LOGGING_IN_USER = "auth:LOGGING_IN_USER";
export const LOGGED_IN_USER = "auth:LOGGED_IN_USER";
export const ERROR_LOGGING_IN_USER = "auth:ERROR_LOGGING_IN_USER";

export const LOGGING_OUT_USER = "auth:LOGGING_OUT_USER";
export const LOGGED_OUT_USER = "auth:LOGGED_OUT_USER";
export const ERROR_LOGGING_OUT_USER = "auth:ERROR_LOGGING_OUT_USER";
