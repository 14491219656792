import React from "react";

function CaseForm({
                    toggleComplaintField,
                    displayIssueType,
                    initialValues,
                    toggleOtherComplaintField,
                    displayOtherIssueType,
                    onSubmit,
                    errors,
                    handleChange,
                    handleInputChange,
                    caseRef,
                    showEscalateButton,
                  }) {

  const genderOptionGroup = [
    {label: "--Gender--", value: ""},
    {label: "Male", value: "Male"},
    {label: "Female", value: "Female"},
  ];

  const caseCategoryOptionGroup = [
    {label: "--Issue Category--", value: ""},
    {label: "Complaint", value: "1"},
    {label: "Enquiry", value: "2"},
  ];

  const organizationOptionGroup = [
    {label: "--Organization--", value: ""},
    {label: "Police", value: "Police"},
    {label: "Immigration", value: "Immigration"},
    {label: "Civil defence", value: "Civil defence"},
    {label: "Prisons", value: "Prisons"},
    {label: "Army", value: "Army"},
    {label: "Navy", value: "Navy"},
    {label: "Air force", value: "Air force"},
    {label: "University", value: "University"},
    {label: "polytechnics", value: "polytechnics"},
    {label: "College of Education", value: "College of Education"},
    {label: "Mda's", value: "Mda's"},
  ];

  const caseTypeOptionGroup = [
    {label: "--Issue Type--", value: "0"},
    {label: "Unpaid salary", value: "1"},
    {label: "Short payment", value: "2"},
    {label: "Not captured", value: "3"},
    {label: "Payslip request", value: "4"},
    {label: "Arrears", value: "5"},
    {label: "Other", value: "6"},
  ];

  const locationOptionGroup = [
    {label: "--Location--", value: ""},
    {value: "1", label: "Abia"},
    {value: "2", label: "Adamawa"},
    {value: "3", label: "Akwa Ibom"},
    {value: "4", label: "Anambra"},
    {value: "5", label: "Bauchi"},
    {value: "6", label: "Bayelsa"},
    {value: "7", label: "Benue"},
    {value: "8", label: "Borno"},
    {value: "9", label: "Cross River"},
    {value: "10", label: "Delta"},
    {value: "11", label: "Ebonyi"},
    {value: "12", label: "Edo"},
    {value: "13", label: "Ekiti"},
    {value: "14", label: "Enugu"},
    {value: "15", label: "Gombe"},
    {value: "16", label: "Imo"},
    {value: "17", label: "Jigawa"},
    {value: "18", label: "Kaduna"},
    {value: "19", label: "Kano"},
    {value: "20", label: "Katsina"},
    {value: "21", label: "Kebbi"},
    {value: "22", label: "Kogi"},
    {value: "23", label: "Kwara"},
    {value: "24", label: "Lagos"},
    {value: "25", label: "Nasarawa"},
    {value: "26", label: "Niger"},
    {value: "27", label: "Ogun"},
    {value: "28", label: "Ondo"},
    {value: "29", label: "Osun"},
    {value: "30", label: "Oyo"},
    {value: "31", label: "Plateau"},
    {value: "32", label: "Rivers"},
    {value: "33", label: "Sokoto"},
    {value: "34", label: "Taraba"},
    {value: "35", label: "Yobe"},
    {value: "36", label: "Zamfara"},
    {value: "37", label: "FCT"},
  ];

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-12 col-xs-12 col-md-12">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="mdi mdi-account"/>
                </span>
              </div>
              <input
                onInput={handleInputChange}
                value={initialValues.firstName}
                onChange={handleChange}
                type="text"
                name="firstName"
                className="form-control"
                placeholder="First Name"
              />
            </div>
            <small id="passwordHelpBlock" className="form-text text-muted">
              Your first name must be 30 characters long, contain letters and
              numbers, and must not contain spaces, special characters, or
              emoji.
            </small>
            {errors.firstName && (
              <div className="mt-2">
                <span className="text-danger">{errors.firstName}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xs-12 col-md-12">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="mdi mdi-account"/>
                </span>
              </div>
              <input
                onInput={handleInputChange}
                value={initialValues.lastName}
                onChange={handleChange}
                type="text"
                name="lastName"
                className="form-control"
                placeholder="Last Name"
              />
            </div>
            <small id="passwordHelpBlock" className="form-text text-muted">
              Your last name must be 30 characters long, contain letters and
              numbers, and must not contain spaces, special characters, or
              emoji.
            </small>
            {errors.lastName && (
              <div className="mt-2">
                <span className="text-danger">{errors.lastName}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xs-12 col-md-12">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="mdi mdi-email"/>
                </span>
              </div>
              <input
                onInput={handleInputChange}
                value={initialValues.email}
                onChange={handleChange}
                type="text"
                name="email"
                className="form-control"
                placeholder="Email"
              />
            </div>
            <small id="passwordHelpBlock" className="form-text text-muted">
              Your email must be 50 characters long, contain letters and
              numbers, and must not contain spaces, special characters, or
              emoji. Only email domains: .com, .net, and .ng are allowed
            </small>
            {errors.email && (
              <div className="mt-2">
                <span className="text-danger">{errors.email}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xs-12 col-md-12">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="mdi mdi-phone"/>
                </span>
              </div>
              <input
                onInput={handleInputChange}
                value={initialValues.phone}
                onChange={handleChange}
                type="text"
                name="phone"
                className="form-control"
                placeholder="Phone"
              />
            </div>
            <small id="passwordHelpBlock" className="form-text text-muted">
              Your phone number must be 11 digits long, contain only numbers,
              and must not contain spaces, special characters, or emoji.
            </small>
            {errors.phone && (
              <div className="mt-2">
                <span className="text-danger">{errors.phone}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xs-12 col-md-12">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="mdi mdi-account-key"/>
                </span>
              </div>
              <input
                onInput={handleInputChange}
                value={initialValues.ippisNumber}
                onChange={handleChange}
                type="text"
                name="ippisNumber"
                className="form-control"
                placeholder="IPPIS number"
              />
            </div>
            <small id="passwordHelpBlock" className="form-text text-muted">
              Your IPPIS number must be 8-20 characters long, contain letters
              and numbers, and must not contain spaces, special characters, or
              emoji.
            </small>
            {errors.ippisNumber && (
              <div className="mt-2">
                <span className="text-danger">{errors.ippisNumber}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className=" row">
        <div className="col-12 col-xs-12 col-md-9 col-sm-12">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-append bg-custom b-0">
                <span className="input-group-text">
                  <span>
                    <span style={{fontSize: "8px !important"}}>
                      Date of Birth
                    </span>
                  </span>
                </span>
              </div>
              <input
                onInput={handleInputChange}
                value={initialValues.dob}
                onChange={handleChange}
                type="date"
                className="form-control"
                name="dob"
                placeholder="Date of Birth"
                id="datepicker"
              />
            </div>
            {errors.dob && (
              <div className="mt-2">
                <span className="text-danger">{errors.dob}</span>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-xs-12 col-md-3">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-append bg-custom b-0">
                <span className="input-group-text">
                  <i className="mdi mdi-gender-male-female"/>
                </span>
              </div>
              <select
                value={initialValues.gender}
                onChange={handleChange}
                onInput={handleInputChange}
                className="select2 form-control custom-select"
                name="gender"
                id=""
              >
                {genderOptionGroup.map((gender) => (
                  <option key={gender.value} value={gender.value}>
                    {gender.label}
                  </option>
                ))}
              </select>
            </div>
            {errors.gender && (
              <div className="mt-2">
                <span className="text-danger">{errors.gender}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xs-12 col-md-6">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-append bg-custom b-0">
                <span className="input-group-text">
                  <i className="mdi mdi-worker"/>
                </span>
              </div>
              <select
                value={initialValues.organization}
                onChange={handleChange}
                name="organization"
                className="select2 form-control custom-select"
              >
                {organizationOptionGroup.map((organization) => (
                  <option key={organization.value} value={organization.value}>
                    {organization.label}
                  </option>
                ))}
              </select>
            </div>
            {errors.organization && (
              <div className="mt-2">
                <span className="text-danger">{errors.organization}</span>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-xs-12 col-md-6">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-append bg-custom b-0">
                <span className="input-group-text">
                  <i className="mdi mdi-map-marker"/>
                </span>
              </div>
              <select
                value={initialValues.location}
                onChange={handleChange}
                className="select2 form-control custom-select"
                name="location"
              >
                {locationOptionGroup.map((location) => (
                  <option key={location.value} value={location.value}>
                    {location.label}
                  </option>
                ))}
              </select>
            </div>
            {errors.location && (
              <div className="mt-2">
                <span className="text-danger">{errors.location}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-xs-12 col-md-6">
          <div className="form-group">
            <div className="input-group">
              <div className="input-group-append bg-custom b-0">
                <span className="input-group-text">
                  <i className="mdi mdi-group"/>
                </span>
              </div>
              <select
                value={initialValues.caseCategory}
                className="select2 form-control custom-select"
                onChange={toggleComplaintField}
                name="caseCategory"
              >
                {caseCategoryOptionGroup.map((category) => (
                  <option key={category.value} value={category.value}>
                    {category.label}
                  </option>
                ))}
              </select>
            </div>
            {errors.caseCategory && (
              <div className="mt-2">
                <span className="text-danger">{errors.caseCategory}</span>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 col-xs-12 col-md-6">
          <div className="form-group">
            <div style={{display: displayIssueType ? "block" : "none"}}>
              <div className="input-group">
                <div className="input-group-append bg-custom b-0">
                  <span className="input-group-text">
                    <i className="mdi mdi-alert"/>
                  </span>
                </div>
                <select
                  value={initialValues.caseType}
                  className="select2 form-control custom-select"
                  onChange={toggleOtherComplaintField}
                  ref={caseRef}
                  name="caseType"
                >
                  {caseTypeOptionGroup.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {errors.caseType && (
              <div className="mt-2">
                <span className="text-danger">{errors.caseType}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="form-group"
        style={{display: displayOtherIssueType ? "block" : "none"}}
      >
        <label htmlFor="other_case">Please specify issue here</label>
        <textarea
          onInput={handleInputChange}
          value={initialValues.otherType}
          onChange={handleChange}
          className="form-control"
          style={{height: "100px"}}
          name="otherType"
        />
        {errors.otherType && (
          <div className="mt-2">
            <span className="text-danger">{errors.otherType}</span>
          </div>
        )}
      </div>
      <div className="form-group row">
        <div
          className="col-12 mt-40"
          style={{display: showEscalateButton ? "block" : "none"}}
        >
          <button type="submit" className="btn btn-outline-success btn-block">
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}

export default CaseForm;
