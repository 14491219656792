import React from 'react';
import {Formik, Form, Field} from "formik";

function SearchForm({initialValues, handleSubmit}) {
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {
        ({errors, touched, handleChange, handleBlur}) => (
          <Form id="search-form" action="#">
            <Field
              type="search"
              placeholder="Search here..."
              name="search"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <button type="submit">
              <span>
                <i className="fa fa-search"/>
              </span>
            </button>
          </Form>
        )
      }
    </Formik>
  );
}

export default SearchForm;