import React from "react";
import { Formik, Field, Form } from "formik";

const SubscriptionForm = ({
  initialValues,
  handleSubmit,
  userSchema,
  isCreatingSubscription,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={userSchema}
      onSubmit={(values, { resetForm }) => {
        handleSubmit(values);
        resetForm(values);
      }}
    >
      {({ errors, touched, handleChange, handleBlur, values }) => (
        <Form>
          <div className="mc-form">
            <Field
              type="text"
              value={values.email}
              name="email"
              className="email"
              placeholder="Enter your e-mail address"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {errors.email && touched.email && (
              <span className="text-danger">{errors.email}</span>
            )}
            <button
              disabled={!!isCreatingSubscription}
              className="default-btn"
              type="submit"
              name="subscribe"
            >
              <span>subscribe</span>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SubscriptionForm;
