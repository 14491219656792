import React, { Component, Fragment } from "react";
import Banner from "../components/banner/Banner";
import ContactMap from "../components/maps/ContactMap";
import ContactForm from "../components/forms/ContactForm";
import BannerImage from "../assets/img/banner/banner_new.jpg";
import * as Yup from "yup";
import { connect } from "react-redux";
import { createContactMessage, clearAlerts } from "../actions/frontend.action";
import SweetAlert from "react-bootstrap-sweetalert";
import Loader from "../components/loader/Loader";
import { getCsrf } from "../actions/frontend.action";

const contactSchema = Yup.object().shape({
  name: Yup.string().max(50, "Name too long").required("Please provide name"),
  email: Yup.string()
    .max(50, "Email address too long")
    .email("Email address is invalid")
    .required("Please provide email address"),
  subject: Yup.string()
    .max(40, "Subject too long")
    .required("Subject field is required"),
  message: Yup.string()
    .max(500, "Message should not be more than 500 characters.")
    .required("Message field is required"),
});

class ContactUsPage extends Component {
  state = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };

  componentDidMount() {
    this.props.getCsrf();
  }

  handleSubmit = (values) => {
    this.props.createContactMessage(values, this.props.csrf);
    this.setState({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };

  onConfirm = () => {
    this.props.clearAlerts();
  };
  onCancel = () => {
    this.props.clearAlerts();
  };

  render() {
    //TODO add subject selection
    const {
      createdContactMessage,
      createContactMessageError,
      hasErrorCreatingContactMessage,
      isCreatedContactMessage,
      isCreatingContactMessage,
    } = this.props;
    return (
      <Fragment>
        <Banner title="Contact Us" BannerImage={BannerImage} />
        <ContactMap />

        <div className="contact-area pt-150 pb-140">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-sm-5 col-xs-12">
                <div className="contact-contents text-center">
                  <div className="single-contact mb-65">
                    <div className="contact-icon">
                      <img
                        src="../assets/img/contact/contact1.png"
                        alt="contact"
                      />
                    </div>
                    <div className="contact-add">
                      <h3>address</h3>
                      <p>
                        Office of the Accountant-General <br /> of the
                        Federation
                      </p>
                      <p>Treasury House Ladoke Akintola Boulevard</p>
                      <p>Garki 11, Abuja, Nigeria.</p>
                    </div>
                  </div>
                  <div className="single-contact mb-65">
                    <div className="contact-icon">
                      <img
                        src="../assets/img/contact/contact2.png"
                        alt="contact"
                      />
                    </div>
                    <div className="contact-add">
                      <h3>phone</h3>
                      <p>Call any of our agents:</p>
                      <p>09087005735</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-sm-7 col-xs-12">
                <div className="reply-area">
                  <h3>LEAVE A message</h3>
                  <ContactForm
                    contactSchema={contactSchema}
                    initialValues={this.state}
                    handleSubmit={this.handleSubmit}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {isCreatedContactMessage && (
          <SweetAlert
            closeOnClickOutside={true}
            success
            title="Data saved!"
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
            timeout={5000}
            confirmBtnBsStyle="success"
          >
            <span className="text-success">{createdContactMessage}</span>
          </SweetAlert>
        )}
        {hasErrorCreatingContactMessage && (
          <SweetAlert
            closeOnClickOutside={true}
            danger
            title="Error!"
            onConfirm={this.onConfirm}
            onCancel={this.onCancel}
            confirmBtnBsStyle="danger"
          >
            <span className="text-danger">{createContactMessageError}</span>
          </SweetAlert>
        )}
        <Loader showLoader={isCreatingContactMessage} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isCreatingContactMessage: state.frontendReducer.isCreatingContactMessage,
  isCreatedContactMessage: state.frontendReducer.isCreatedContactMessage,
  hasErrorCreatingContactMessage:
    state.frontendReducer.hasErrorCreatingContactMessage,
  createContactMessageError: state.frontendReducer.createContactMessageError,
  createdContactMessage: state.frontendReducer.createdContactMessage,
  csrf: state.frontendReducer._csrf,
});
const mapActionsToProps = {
  createContactMessage,
  clearAlerts,
  getCsrf,
};
export default connect(mapStateToProps, mapActionsToProps)(ContactUsPage);
