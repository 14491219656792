import React from "react";

function FooterBottom() {
  return (
    <div className="footer-bottom text-center">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 mx-auto">
            <p>
              Copyright ©
              <a
                href="https://ucdev.com.ng"
                target="_blank"
                rel="noopener noreferrer"
              >
                UCDev
              </a>
              {new Date().getFullYear().toString()}. All Right Reserved By
              UCDev.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterBottom;
