export const CREATING_CONTACT_MESSAGE = "admin:CREATING_CONTACT_MESSAGE";
export const CREATED_CONTACT_MESSAGE = "admin:CREATED_CONTACT_MESSAGE";
export const ERROR_CREATING_CONTACT_MESSAGE =
  "admin:ERROR_CREATING_CONTACT_MESSAGE";

export const CREATING_SUBSCRIPTION = "admin:CREATING_SUBSCRIPTION";
export const CREATED_SUBSCRIPTION = "admin:CREATED_SUBSCRIPTION";
export const ERROR_CREATING_SUBSCRIPTION = "admin:ERROR_CREATING_SUBSCRIPTION";

export const CREATING_CASE = "case:CREATING_CASE";
export const CREATED_CASE = "case:CREATED_CASE";
export const ERROR_CREATING_CASE = "case:ERROR_CREATING_CASE";

export const CLEAR_ALERTS = "alert:CLEAR_ALERTS";

export const FETCHING_ALL_POSTS = "post:FETCHING_ALL_POSTS";
export const FETCHED_ALL_POSTS = "post:FETCHED_ALL_POSTS";
export const ERROR_FETCHING_ALL_POSTS = "post:ERROR_FETCHING_ALL_POSTS";

export const FETCHING_ALL_ABOUT_US = "post:FETCHING_ALL_ABOUT_US";
export const FETCHED_ALL_ABOUT_US = "post:FETCHED_ALL_ABOUT_US";
export const ERROR_FETCHING_ALL_ABOUT_US = "post:ERROR_FETCHING_ALL_ABOUT_US";

export const FETCHING_ALL_SERVICES = "post:FETCHING_ALL_SERVICES";
export const FETCHED_ALL_SERVICES = "post:FETCHED_ALL_SERVICES";
export const ERROR_FETCHING_ALL_SERVICES = "post:ERROR_FETCHING_ALL_SERVICES";

export const ERROR_GETTING_CSRF_TOKEN = "auth:ERROR_GETTING_CSRF_TOKEN";
export const GET_CSRF_TOKEN = "auth:GET_CSRF_TOKEN";

export const SHOW_CHAT_MODAL = "chat:SHOW_CHAT_MODAL";
export const CLOSE_CHAT_MODAL = "chat:SHOW_CHAT_MODAL";
