import React, {Component, Fragment} from "react";
import AboutArea from "./AboutArea";
import ServiceArea from "./ServiceArea";
import CoursesArea from "./CoursesArea";
import SubscribeArea from "./SubscribeArea";
import PostsArea from "./PostsArea";

class Contents extends Component {
  render() {
    return (
      <Fragment>
        {/* <!-- About Start -->*/}
        <AboutArea/>
        {/*<!-- About End -->*/}
        {/*<!-- Service Start -->*/}
        <ServiceArea/>
        {/* <!-- Service End -->*/}
        {/*<!-- Courses Area Start -->*/}
        <CoursesArea/>
        {/* <!-- Courses Area End -->*/}
        <PostsArea/>
        {/*<!-- Subscribe Start -->*/}
        <SubscribeArea/>
        {/*<!-- Subscribe End -->*/}
      </Fragment>
    );
  }
}

export default Contents;
