import React from "react";
import { withRouter, Link } from "react-router-dom";
const url = process.env.PUBLIC_URL;
function Events({ event: { id, title, day, month, year, link, img } }) {
  return (
    <div className="single-post mb-30">
      <div className="single-post-img">
        <Link to={`${url}/${link}/${id}`}>
          <img src={`${process.env.REACT_APP_IMAGE_URL}${img}`} width="100%" alt="posts"
               crossOrigin="anonymous"/>
          <div className="blog-hover">
            <i className="fa fa-link"/>
          </div>
        </Link>
      </div>
      <div className="single-post-content">
        <h4>
          <Link to={`${url}/${link}/${id}`}>{title}</Link>
        </h4>
        <p>
          {month} {day}, {year}
        </p>
      </div>
    </div>
  );
}

export default withRouter(Events);
