import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers/root.reducer";
import Thunk from "redux-thunk";

const initialState = {};
const middleware = [Thunk];
const { NODE_ENV } = process.env;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const storeConfig =
  NODE_ENV === "production"
    ? createStore(rootReducer, initialState, applyMiddleware(...middleware))
    : createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middleware))
      );

export default storeConfig;
