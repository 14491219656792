import React, {Component} from "react";
import AboutUsImage from "../assets/img/about/about_us_img.jpg";
import {Link} from "react-router-dom";

const url = process.env.PUBLIC_URL;

class AboutArea extends Component {
  render() {
    return (
      <div className="about-area pt-150">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-6">
              <div className="about-content">
                <h2>
                  WELCOME TO <span>IPPIS</span>
                </h2>
                <p>
                  IPPIS Secretariat is a Department under the Office of the
                  Accountant-General of the Federation responsible for payment
                  of salaries and wages directly to Government employee’s bank
                  account with appropriate deductions and remittances of 3rd
                  party payments such as; Federal Inland Revenue Service, State
                  Boards of Internal Revenue, National Health Insurance Scheme,
                  National Housing Fund, Pension Fund Administrator, Cooperative
                  Societies, Trade Unions Dues, Association Dues and Bank Loans.
                </p>
                <p className="hidden-sm">
                  We are committed to efficient and effective service delivery.
                  Our main aim is to pay accurately and on time within statutory
                  and contractual regulations. We aim to provide a payroll
                  service that is customer focused and that utilises technology
                  wherever possible.
                </p>
                <Link className="default-btn" to={`${url}/about`}>
                  read more
                </Link>
              </div>
            </div>
            <div className="col-md-6 col-sm-6">
              <div className="about-img">
                <img alt='abt-us-img' src={AboutUsImage}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutArea;
