import React from "react";

export default function Banner({ title, BannerImage }) {
  const BannerStyle = {
    background: `rgba(0, 0, 0, 0) url(${BannerImage}) no-repeat scroll center center / cover`,
    boxShadow: "inset 0 0 0 100vw rgba(0,0,0,0.5)",
    position: "relative",
    width: "100%",
    zIndex: 1,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <div className="banner-area-wrapper">
      <div style={BannerStyle}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner-content-wrapper">
                <div className="banner-content text-center">
                  <h2>{title}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
