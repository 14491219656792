import {
  LOGGING_IN_USER,
  LOGGED_OUT_USER,
  LOGGING_OUT_USER,
  LOGGED_IN_USER,
  ERROR_LOGGING_IN_USER,
  ERROR_LOGGING_OUT_USER,
  USER_GETTING_OTP,
  USER_GET_OTP,
  USER_ERROR_GETTING_OTP,
} from "../constants/authentication.constant";

const initialState = {
  isLoggingIn: false,
  isLoggedIn: false,
  loginHasError: false,
  isLoggingOut: false,
  isLoggedOut: false,
  logoutHasError: false,
  isGettingOtp: false,
  isGetOtp: false,
  hasErrorGettingOtp: false,
  loggedIn: "",
  loggedOut: "",
  error: "",
  otpDetails: [],
};

const authenticationReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case LOGGING_IN_USER:
      return (state = {
        ...state,
        isLoggingIn: true,
      });

    case LOGGED_IN_USER:
      return (state = {
        ...state,
        isLoggingIn: false,
        isLoggedIn: true,
        loggedIn: payload,
      });

    case ERROR_LOGGING_IN_USER:
      return (state = {
        ...state,
        isLoggingIn: false,
        loginHasError: true,
        error: payload,
      });

    case LOGGING_OUT_USER:
      return (state = {
        ...state,
        isLoggingOut: true,
      });

    case LOGGED_OUT_USER:
      return (state = {
        ...state,
        isLoggedIn: state.isLoggedIn && false,
        isLoggedOut: true,
        loggedOut: payload,
      });

    case ERROR_LOGGING_OUT_USER:
      return (state = {
        ...state,
        isLoggedIn: state.isLoggedIn && false,
        isLoggedOut: false,
        error: payload,
      });

    case USER_GETTING_OTP:
      return (state = {
        ...state,
        isGettingOtp: true,
      });
    case USER_GET_OTP:
      return (state = {
        ...state,
        isGettingOtp: false,
        isGetOtp: true,
        otpDetails: payload,
      });
    case USER_ERROR_GETTING_OTP:
      return (state = {
        ...state,
        isGettingOtp: false,
        isGetOtp: false,
        hasErrorGettingOtp: true,
        error: payload,
      });

    default:
      state = { ...state };
  }

  return state;
};

export default authenticationReducer;
