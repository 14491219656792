import React, { Fragment, useEffect } from "react";
import Banner from "../components/banner/Banner";
import { Card, ListGroup } from "react-bootstrap";
import BannerImage from "../assets/img/banner/banner_new.jpg";
import { connect } from "react-redux";
import { fetchAboutUs } from "../actions/frontend.action";
import ReactParseHtml from "react-html-parser";
import config from "../helpers";

const { about, management } = config;

function AboutUsPage({ fetchAboutUs, data }) {
  useEffect(() => {
    fetchAboutUs();
  }, [fetchAboutUs]);

  const activeData =
    data && data.length > 0
      ? data.find((content) => content.setDefault === true)
      : { aboutUs: about, management };
  return (
    <Fragment>
      <Banner title="About Us" BannerImage={BannerImage} />
      <div className="blog-details-area pt-150 pb-20">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="blog-details">
                <div className="blog-details-content">
                  <h2>About Us </h2>
                  {ReactParseHtml(activeData.aboutUs)}
                </div>
                <div className="blog-details-content">
                  <h2>IPPIS Management</h2>
                  <div className="quote">
                    {ReactParseHtml(activeData.management)}
                  </div>
                </div>
              </div>
              <div className="row mt-55">
                <div className="col-12 col-md-4 col-lg-6 mt-2 mb-5">
                  <h3 className="mb-10">Our Mission</h3>
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        To pay FGN employees on-time and accurately within
                        statutory and contractual obligations
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="col-12 col-md-4 col-lg-6 mt-2 mb-5">
                  <h3 className="mb-10">Office Address</h3>
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        Office of the Accountant-General of the Federation
                        Treasury House Ladoke Akintola Boulevard, Garki 11,
                        Abuja, Nigeria.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="blog-sidebar right">
                <div className="single-blog-widget mb-47">
                  <h3>what we do</h3>
                  <Card style={{ width: "18rem" }}>
                    <Card.Body>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          Management of FGN employees records
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Payment of Salary and Wages to FGN employees
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Deductions of taxes and other third payments
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Remittance of payroll deductions to third party
                        </ListGroup.Item>
                        <ListGroup.Item>
                          Enrolment of employees into IPPIS database
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </div>
                <div className="single-blog-widget mb-47">
                  <h3>Our Vision</h3>
                  <Card style={{ width: "18rem" }}>
                    <Card.Body>
                      <Card.Text>
                        To have a centralised payroll system that meet the needs
                        of FGN employees and helps the Government to plan and
                        manage payroll budget by ensuring proper control of
                        personnel cost.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className="single-blog-widget mb-47">
                  <h3>Our Core Values</h3>
                  <Card style={{ width: "18rem" }}>
                    <Card.Body>
                      <ListGroup variant="flush">
                        <ListGroup.Item>Confidentiality</ListGroup.Item>
                        <ListGroup.Item>Individuality/empathy</ListGroup.Item>
                        <ListGroup.Item>Teamwork</ListGroup.Item>
                        <ListGroup.Item>Honesty</ListGroup.Item>
                        <ListGroup.Item>Accuracy/Timeliness</ListGroup.Item>
                        <ListGroup.Item>Trust/Accountability</ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  isFetchingAboutUs: state.frontendReducer.isFetchingAboutUs,
  isFetchedAboutUs: state.frontendReducer.isFetchedAboutUs,
  hasErrorFetchingAboutUs: state.frontendReducer.hasErrorFetchingAboutUs,
  fetchAboutUsError: state.frontendReducer.fetchAboutUsError,
  fetchedAboutUs: state.frontendReducer.fetchedAboutUs,
  data: state.frontendReducer.aboutUs,
});

export default connect(mapStateToProps, { fetchAboutUs })(AboutUsPage);
