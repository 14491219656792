import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/img/logo/ippis-logo-new.png";

const url = process.env.PUBLIC_URL;

function FooterMain() {
  return (
    <div className="main-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12">
            <div className="single-widget pr-60">
              <div className="footer-logo pb-25">
                <Link to={`${url}/`}>
                  <img src={Logo} alt="logo" />
                </Link>
              </div>
              <p>
                Improving the effectiveness and efficiency in the storage of
                personnel records and administration of monthly payroll.
              </p>
              <div className="footer-social">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/devitems/?ref=bookmarks">
                      <i className="zmdi zmdi-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.pinterest.com/devitemsllc/">
                      <i className="zmdi zmdi-pinterest" />
                    </a>
                  </li>
                  <li>
                    <a href="/">
                      <i className="zmdi zmdi-vimeo" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/devitemsllc">
                      <i className="zmdi zmdi-twitter" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="single-widget">
              <h3>information</h3>
              <ul>
                <li>
                  <a href="https://registration.ippis.gov.ng/">enrolment</a>
                </li>
                <li>
                  <Link to={`${url}/support`}>Frequently asked questions</Link>
                </li>
                <li>
                  <Link to={`${url}/support`}>Help and support</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-2 col-sm-6 col-xs-12">
            <div className="single-widget">
              <h3>useful links</h3>
              <ul>
                <li>
                  <Link to={`${url}/about`}>about us</Link>
                </li>
                <li>
                  <Link to={`${url}/events`}>events</Link>
                </li>
                <li>
                  <Link to={`${url}/contact`}>contact</Link>
                </li>
                <li>
                  <Link to={`${url}/chat`}>chat</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12">
            <div className="single-widget">
              <h3>get in touch</h3>
              <p>
                Office of the Accountant-General of the Federation
                <br />
                Treasury House, Ladoke Akintola Boulevard
                <br />
                Garki 11, Abuja
              </p>
              <p>+234 908 700 573 5</p>
              <p>
                support.ippis.gov.ng
                <br />
                www.ippis.gov.ng
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FooterMain;
