import React, {Component} from "react";
import QuestionIcon from "../assets/img/course/what_is_ippis.png";
import HistoryIcon from "../assets/img/course/history.png";
import PortFolioIcon from "../assets/img/course/ippis_portfolio.png";
import TeamIcon from "../assets/img/course/team.png";
import {Link} from "react-router-dom";

const url = process.env.PUBLIC_URL;


class CoursesArea extends Component {
  render() {
    return (
      <div className="courses-area two pt-150 pb-150 text-center">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 mx-auto">
              <div className="section-title">
                <img src={`${url}assets/img/icon/section1.png`} alt="section-title"/>
                <h2>WHO WE ARE</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-course">
                <div className="course-img">
                  <Link to={`${url}/about`}>
                    <img src={QuestionIcon} alt="course"/>
                    <div className="course-hover">
                      <i className="fa fa-link"/>
                    </div>
                  </Link>
                </div>
                <div className="course-content">
                  <h3>
                    <Link to={`${url}/about`}>What is IPPIS</Link>
                  </h3>
                  <p>
                    IPPIS Secretariat is a Department under the Office of the
                    Accountant-General of the Federation responsible for payment
                    of salaries...
                  </p>
                  <Link className="default-btn" to={`${url}/about`}>
                    read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-12">
              <div className="single-course">
                <div className="course-img">
                  <Link to={`${url}/about`}>
                    <img src={HistoryIcon} alt="course"/>
                    <div className="course-hover">
                      <i className="fa fa-link"/>
                    </div>
                  </Link>
                </div>
                <div className="course-content">
                  <h3>
                    <Link to={`${url}/about`}>Our History</Link>
                  </h3>
                  <p>
                    Integrated Payroll and Personnel information system (IPPIS)
                    was conceptualized in October 2006 by the Federal...
                  </p>
                  <Link className="default-btn" to={`${url}/history`}>
                    read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3 hidden-sm col-xs-12">
              <div className="single-course">
                <div className="course-img">
                  <Link to={`${url}/about`}>
                    <img src={PortFolioIcon} alt="course"/>
                    <div className="course-hover">
                      <i className="fa fa-link"/>
                    </div>
                  </Link>
                </div>
                <div className="course-content">
                  <h3>
                    <Link to={`${url}/about`}>What We Do</Link>
                  </h3>
                  {/**
                   * IPPIS is a Department within the Office of the Accountant-General of the Federation responsible for timely and accurate payment of salaries, wages directly to Government employee’s bank account with appropriate deductions and remittances of 3rd party payments such as: Federal Inland Revenue Service, State Boards of Internal Revenue, National Health Insurance Scheme, National Housing Fund,Pension Fund Administrator, Cooperative Societies, Trade Unions/Association Dues Bank Loans.
                   */}
                  <p>
                    IPPIS is a Department within the Office of the
                    Accountant-General of the Federation responsible for timely
                    and accurate...
                  </p>
                  <Link className="default-btn" to={`${url}/about`}>
                    read more
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-md-3 hidden-sm col-xs-12">
              <div className="single-course">
                <div className="course-img">
                  <Link to={`${url}/about`}>
                    <img src={TeamIcon} alt="course"/>
                    <div className="course-hover">
                      <i className="fa fa-link"/>
                    </div>
                  </Link>
                </div>
                <div className="course-content">
                  <h3>
                    <Link to={`${url}/about`}>Our Team</Link>
                  </h3>
                  {/**
                   * IPPIS Department carries out its functions in collaboration with; the Federal Ministry of Finance, Office of the Head of Service, Civil Service Commission, Office of the Auditor General of the Federation and MDAs that are enrolled on IPPIS.
                   */}
                  <p>
                    IPPIS Department carries out its functions in collaboration
                    with; the Federal Ministry of Finance,Office of the Head of
                    Service...
                  </p>
                  <Link className="default-btn" to={`${url}/about`}>
                    read more
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CoursesArea;
