import React, { Component, Fragment } from "react";
import LandingPageSlider from "../components/sliders/LandingPageSlider";
import Contents from "../components/Contents";

class LandingPage extends Component {
  render() {
    return (
      <Fragment>
        <LandingPageSlider />
        <Contents />
      </Fragment>
    );
  }
}

export default LandingPage;
