import {
  ERROR_CREATING_CASE,
  CREATED_CASE,
  CREATING_CASE,
  CLEAR_ALERTS,
  CREATED_CONTACT_MESSAGE,
  CREATING_CONTACT_MESSAGE,
  ERROR_CREATING_CONTACT_MESSAGE,
  CREATING_SUBSCRIPTION,
  CREATED_SUBSCRIPTION,
  ERROR_CREATING_SUBSCRIPTION,
  ERROR_FETCHING_ALL_SERVICES,
  FETCHED_ALL_SERVICES,
  FETCHING_ALL_SERVICES,
  ERROR_FETCHING_ALL_POSTS,
  FETCHED_ALL_POSTS,
  FETCHING_ALL_POSTS,
  ERROR_GETTING_CSRF_TOKEN,
  GET_CSRF_TOKEN,
  ERROR_FETCHING_ALL_ABOUT_US,
  FETCHED_ALL_ABOUT_US,
  FETCHING_ALL_ABOUT_US,
  CLOSE_CHAT_MODAL,
  SHOW_CHAT_MODAL,
} from "../constants/frontend.constant";
import {
  createCaseService,
  fetchAllAboutUsService,
  fetchPostsService,
  fetchServicesService,
  saveContactMessageService,
  saveSubscriptionService,
} from "../services/frontend.service";
import { _getCsrfService } from "../services/frontend.service";

export const getCsrf = () => (dispatch) => {
  _getCsrfService()
    .then((csrf) => {
      dispatch({ type: GET_CSRF_TOKEN, payload: csrf });
    })
    .catch((error) => {
      dispatch({ type: ERROR_GETTING_CSRF_TOKEN, payload: error });
    });
};

export const createCase = (data, csrf) => (dispatch) => {
  dispatch({ type: CREATING_CASE });
  try {
    createCaseService(data, csrf)
      .then((response) => dispatch({ type: CREATED_CASE, payload: response }))
      .catch((error) => {
        dispatch({
          type: ERROR_CREATING_CASE,
          payload:
            typeof error.toJSON === "function" ? error.toJSON().message : error,
        });
      });
  } catch (e) {
    dispatch({ type: ERROR_CREATING_CASE, payload: e.toJSON().message });
  }
};

export const fetchPosts = () => (dispatch) => {
  dispatch({ type: FETCHING_ALL_POSTS });

  try {
    fetchPostsService()
      .then((response) => {
        dispatch({ type: FETCHED_ALL_POSTS, payload: response });
      })
      .catch((error) =>
        dispatch({
          type: ERROR_FETCHING_ALL_POSTS,
          payload:
            typeof error.toJSON === "function" ? error.toJSON().message : error,
        })
      );
  } catch (e) {
    dispatch({ type: ERROR_FETCHING_ALL_POSTS, payload: e });
  }
};

export const fetchAboutUs = () => (dispatch) => {
  dispatch({ type: FETCHING_ALL_ABOUT_US });
  fetchAllAboutUsService()
    .then((response) => {
      dispatch({ type: FETCHED_ALL_ABOUT_US, payload: response });
    })
    .catch((error) =>
      dispatch({
        type: ERROR_FETCHING_ALL_ABOUT_US,
        payload:
          typeof error.toJSON === "function" ? error.toJSON().message : error,
      })
    );
};

export const fetchServices = (data) => (dispatch) => {
  dispatch({ type: FETCHING_ALL_SERVICES });
  try {
    fetchServicesService(data)
      .then((response) => {
        if (response.success) {
          dispatch({ type: FETCHED_ALL_SERVICES, payload: response.message });
        } else
          dispatch({
            type: ERROR_FETCHING_ALL_SERVICES,
            payload: response.message,
          });
      })
      .catch((error) =>
        dispatch({
          type: ERROR_FETCHING_ALL_SERVICES,
          payload:
            typeof error.toJSON === "function" ? error.toJSON().message : error,
        })
      );
  } catch (e) {
    dispatch({ type: ERROR_FETCHING_ALL_SERVICES, payload: e });
  }
};

export const createContactMessage = (credentials, csrf) => (dispatch) => {
  dispatch({ type: CREATING_CONTACT_MESSAGE });
  try {
    saveContactMessageService(credentials, csrf)
      .then((response) =>
        dispatch({ type: CREATED_CONTACT_MESSAGE, payload: response })
      )
      .catch((error) =>
        dispatch({
          type: ERROR_CREATING_CONTACT_MESSAGE,
          payload:
            typeof error.toJSON === "function" ? error.toJSON().message : error,
        })
      );
  } catch (e) {
    dispatch({ type: ERROR_CREATING_CONTACT_MESSAGE, payload: e });
  }
};

export const createSubscription = (credentials, csrf) => (dispatch) => {
  dispatch({ type: CREATING_SUBSCRIPTION });
  try {
    saveSubscriptionService(credentials, csrf)
      .then((response) =>
        dispatch({ type: CREATED_SUBSCRIPTION, payload: response })
      )
      .catch((error) =>
        dispatch({
          type: ERROR_CREATING_SUBSCRIPTION,
          payload:
            typeof error.toJSON === "function" ? error.toJSON().message : error,
        })
      );
  } catch (e) {
    dispatch({ type: ERROR_CREATING_SUBSCRIPTION, payload: e });
  }
};

export const clearAlerts = () => (dispatch) => dispatch({ type: CLEAR_ALERTS });

export const openChatModal = (value) => (dispatch) =>
  dispatch({ type: SHOW_CHAT_MODAL, payload: value });

export const closeChatModal = (value) => (dispatch) =>
  dispatch({ type: CLOSE_CHAT_MODAL, payload: value });
