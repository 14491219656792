import React from "react";
import GoogleMapReact from "google-map-react";
import Marker from './Marker';

const defaultProps = {
  center: {
    lat: 9.158884, //Current Location
    lng: 7.349036
  },
  zoom: 17
};
export default function ContactMap() {
  return (
    <div className="map-area">
      <div className="google-map-area">
        <div style={{height: "440px", width: "100%"}}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key:`${process.env.REACT_APP_MAP_KEY}`
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <Marker
              text={"OAGF quarters"}
              lat={"9.158884"}
              lng={"7.349036"}
            />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
}

